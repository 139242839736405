import { GenericComponentProps } from '../utils/GenericComponents';
import React, { ElementType, JSX } from 'react';
import { classNames } from '../styling/StylingUtils';
import useRefCallback from '../hooks/UseRefCallback';

/** A div (or any other element defined by the `as` prop) that has an animation that can be used to block out
 *  pieces of the DOM that are not loaded yet. */
export default function LoadingIndicator<E extends ElementType>(
  props: GenericComponentProps<E> & {
    children?: React.ReactNode;
    loadingClasses?: string;
    isLoading: boolean | undefined;
  },
): JSX.Element {
  const { isLoading, children, className, as, loadingClasses: userLoadingClasses, ...componentProps } = props;
  const Component = as ?? 'div';
  const loadingClasses = classNames(
    'inline-block transition-all',
    isLoading
      ? classNames('min-w-[2rem] bg-gray-lighter animate-pulse rounded-full text-transparent', userLoadingClasses)
      : 'bg-transparent',
  );

  return (
    <Component {...componentProps} className={classNames(loadingClasses, className)}>
      {children}
    </Component>
  );
}

/** Renders a multi-line text loading indicator based on the text content. If no children are defined, then the min number of lines
 * is determined from the `minRows` prop, which defaults to 4. Renders text content in a `p ` element when finished loading. */
export function ParagraphLoadingIndicator({
  isLoading,
  ...props
}: {
  isLoading: boolean | undefined;
  children?: string | null;
  minRows?: number;
} & GenericComponentProps<'p'>): JSX.Element {
  const [ref, setRef] = useRefCallback<HTMLDivElement>();
  const minRows = props.minRows ?? 4;
  const height = ref?.offsetHeight;
  const rowHeight = 25; // px
  const vertPadding = 10; // px
  const numRows = height ? Math.max(Math.floor(height / rowHeight) + 1, minRows) : 0;

  if (isLoading)
    return (
      <div {...props} className={classNames('relative !text-transparent', props.className)}>
        <div className="absolute w-full">
          {Array.from({ length: props.children ? numRows : minRows }).map((_, i, all) => (
            <div
              key={i}
              className={classNames(
                i === all.length - 1 ? 'w-3/4 sm:w-3/5' : 'w-full',
                'animate-pulse rounded-full bg-gray-lighter text-transparent',
              )}
              style={{
                height: `${rowHeight - vertPadding}px`,
                marginTop: vertPadding / 2,
                marginBottom: vertPadding / 2,
              }}
            />
          ))}
        </div>
        <p ref={setRef}>{props.children}</p>
      </div>
    );
  return <p {...props}>{props.children}</p>;
}
