import { useSearchParams } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { Nullish } from '../data/Nullish';
import _ from 'lodash';

interface QueryTags {
  /** Parsed tags from url query */
  searchTags: string[];
  /** Raw tag input from url query */
  tagsFromUrl: string | null;
  /** Update the url query with new tags */
  setTags: (...tags: Nullish<string>[]) => string;
}

export default function useQueryTags(): QueryTags {
  const [searchParams, setSearchParams] = useSearchParams();
  const tagsFromUrl = searchParams.get('tags');

  const searchTags = useMemo(() => {
    return tagsFromUrl?.split(',') ?? [];
  }, [tagsFromUrl]);

  const setTags = useCallback(
    (...tags: Nullish<string>[]) => {
      searchParams.set('tags', _.compact(tags).join(','));
      setSearchParams(searchParams);

      return searchParams.toString() ? `?${searchParams.toString()}` : '';
    },
    [searchParams, setSearchParams],
  );

  return {
    searchTags,
    tagsFromUrl,
    setTags,
  };
}
