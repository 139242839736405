import { DefaultComponents } from '../DefaultComponents.tsx';
import { useParams } from 'react-router-dom';
import { useSupabase } from '../../data/SupabaseClientProvider.tsx';
import { useRequest } from 'ahooks';
import Spinner from '../Spinner.tsx';
import { ErrorCard } from '../ErrorPanel.tsx';
import { toast } from 'react-toastify';
import React from 'react';
import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet';

export function SingleMessage() {
  const { p: P, h1: H1, h4: H4, a: A } = DefaultComponents;
  const { messageId } = useParams();
  const navigate = useNavigate();
  const supabase = useSupabase().getContext();
  const messageRequest = useRequest(async () => {
    const { data, error } = await supabase.from('ContactForm').select('*').eq('id', messageId).single();
    if (error) {
      console.error(error);
      return null;
    }
    return data;
  });

  if (messageRequest.loading && !messageRequest.data) {
    return <Spinner text={'Loading message...'} />;
  }

  if (messageRequest.error) {
    return <ErrorCard error={messageRequest.error} />;
  }

  if (!messageRequest.data) {
    return (
      <ErrorCard
        error={{
          name: 'Not found',
          message: `Message with id ${messageId} not found. Maybe it was deleted?`,
        }}
      />
    );
  }

  return (
    <>
      <Helmet>
        <title>Message: {`"${messageRequest.data.subject}"`}</title>
      </Helmet>
      <H4 className="text-gray-600">
        Go <A href="/contact/my-messages">back</A> or write <A href="/contact">a new message</A>.
      </H4>
      <hr className="my-4 border border-gray-800" />
      <H1>{messageRequest.data.subject}</H1>
      <H4 className="text-gray-400">
        <span>From </span>
        <span className="font-semibold text-gray-500">{messageRequest.data.name}</span>
        <span className="ml-2">({messageRequest.data.email})</span>
      </H4>
      <P className="text-gray-400">
        <span>Sent on </span>
        <span className="text-gray-500">{new Date(messageRequest.data.created_at).toLocaleString()}</span>
      </P>
      <hr className="my-4 border border-gray-800" />
      <P className="grow">{messageRequest.data.message}</P>
      <P className="text-center text-xs text-gray-600">
        Your inquiries will be answered by email, as soon as possible. Thank you for your patience and understanding.
      </P>
      <button
        className="border-md flex w-fit items-center justify-center gap-2 rounded-md border border-red-500 bg-red-800 bg-opacity-0 p-2 text-red-500 transition-all duration-300 hover:bg-opacity-50"
        onClick={async () => {
          if (!confirm('Are you sure you want to delete this message? This cannot be undone.')) return;

          const { error } = await supabase.from('ContactForm').delete().eq('id', messageId);
          if (error) {
            console.error(error);
            toast.error('There was an error deleting the message. Please try again later.');
          } else {
            toast.success('Message deleted!');
            navigate('/contact/my-messages');
          }
        }}
      >
        Delete message
      </button>
    </>
  );
}
