import { createClient, SupabaseClient } from '@supabase/supabase-js';
import { createContext, useCallback, useContext, useMemo, useRef } from 'react';

export interface Supabase {
  getContext: () => SupabaseClient;
}

const SupabaseContext = createContext<Supabase>({
  getContext: () => {
    throw new Error('Supabase client not initialized');
  },
});

export default function SupabaseClientProvider(props: { children: React.ReactNode; url: string; supabaseKey: string }) {
  const client = useMemo(() => createClient(props.url, props.supabaseKey), [props.url, props.supabaseKey]);
  const getContext = useCallback(() => client, [client]);
  const value = useMemo(() => ({ getContext }), [getContext]);

  return <SupabaseContext.Provider value={value}>{props.children}</SupabaseContext.Provider>;
}

export function useSupabase(): Supabase {
  return useContext(SupabaseContext);
}
