import { Outlet, useParams } from 'react-router-dom';
import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import ArticleThumbnail, { ArticleThumbnailLoadingIndicator } from '../components/articles/ArticleThumbnail';
import { useRequest } from 'ahooks';
import { CreateArticleButton } from '../components/articles/CreateArticleButton';
import { Transition } from '@headlessui/react';
import { transitions } from '../utils/TransitionHelpers';
import { classNames } from '../styling/StylingUtils';
import { Result } from 'ahooks/lib/useRequest/src/types';
import { ArticleInfo, GetArticlesQueryResult, useGetArticlesQuery } from '../data/articlesApi';
import useQueryTags from '../hooks/useQueryTags';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorPanel from '../components/ErrorPanel';
import HeaderLinks from './HeaderLinks';
import Spinner from '../components/Spinner';
import { DemoArticleInfo, DemoThumbnail, isDemoArticle, staticDemos } from './DemoHome.tsx';
import { parseISO } from 'date-fns';
import _ from 'lodash';
import { DefaultComponents } from '../components/DefaultComponents.tsx';
import Fuse from 'fuse.js';
import SearchBar from '../components/SearchBar.tsx';
import { Helmet } from 'react-helmet';

export function ArticleHome() {
  const { articleId } = useParams();
  const { tagsFromUrl, searchTags } = useQueryTags();
  const [searchQuery, setSearchQuery] = useState('');

  const getArticles = useGetArticlesQuery(searchTags, searchQuery);
  const articlesRequest = useRequest(getArticles, {
    refreshDeps: [searchTags, searchQuery],
  });

  const rawTags = useMemo(() => tagsFromUrl?.split(',').map((tag) => ({ name: tag })), [tagsFromUrl]);

  const header = useMemo(() => {
    if (articlesRequest.loading) return getHeader(rawTags);

    return getHeader(articlesRequest?.data?.tags ?? rawTags);
  }, [articlesRequest?.data?.tags, articlesRequest.loading, rawTags]);

  // TODO: add search bar which applies

  return (
    <div className="flex h-full w-full">
      <Helmet>
        <title>{(articlesRequest?.data?.tags ?? rawTags)?.map((tag) => tag.name).join(', ') ?? 'My work'}</title>
        <meta name="description" content={`${header}, and related articles and code demos.`} />
      </Helmet>
      <nav
        className={classNames(
          !articleId ? 'flex md:px-5 lg:px-24 xl:px-48' : 'hidden lg:flex',
          'h-full w-[35%] grow flex-col overflow-y-scroll border-r border-gray-800 pb-8',
        )}
      >
        {!articleId && <HeaderLinks className="bg-gray-950" as="div" />}
        <div className="flex items-center gap-1 px-5 md:px-0">
          {header}
          {articlesRequest.loading && <Spinner className="p-5" text="" />}
        </div>
        <CreateArticleButton onCreate={() => articlesRequest.runAsync().then(console.log)} />
        <SearchBar targetName="an article" onSearch={setSearchQuery} />
        <Content articlesRequest={articlesRequest} searchQuery={searchQuery} />
      </nav>
      <div className={classNames('h-full w-full overflow-y-auto', articleId ? 'block' : 'hidden')}>
        <HeaderLinks className="bg-gray-950" as="div" />
        <ErrorBoundary FallbackComponent={ErrorPanel}>
          <Outlet />
        </ErrorBoundary>
      </div>
    </div>
  );
}

function getHeader(tags?: { name: string | null }[] | null): ReactNode {
  const { h1: H1, h4: H2 } = DefaultComponents;
  const defaultText = 'My work';
  if (!tags || tags.length === 0)
    return (
      <div className="mb-2 p-5">
        <H1>{defaultText}</H1>
        <H2>A collection of all of my creations and thoughts... so far.</H2>
      </div>
    );

  return (
    <div className="mb-2 p-5">
      <H1>{tags?.map((tag) => tag.name).join(', ') ?? defaultText}</H1>
      <H2>And other related content...</H2>
    </div>
  );
}

function Content({
  articlesRequest,
  searchQuery,
}: {
  articlesRequest: Result<GetArticlesQueryResult, []>;
  searchQuery: string;
}) {
  const allSortedArticles: Array<ArticleInfo | DemoArticleInfo> = useMemo(() => {
    const filteredStaticDemos = searchQuery
      ? new Fuse(staticDemos, {
          keys: ['title', 'description'],
        })
          .search(searchQuery)
          .map(({ item }) => item)
      : staticDemos;

    return _.sortBy(
      [
        ...(articlesRequest?.data?.articles ?? []),
        ...(articlesRequest?.data?.tags?.length ? [] : filteredStaticDemos) // only append static demos if we're showing everything
          .map((article) => ({ ...article, isStatic: true })),
      ],
      (article) => -parseISO(article.created_at ?? new Date().toISOString()).valueOf(),
    );
  }, [articlesRequest?.data?.articles, articlesRequest?.data?.tags?.length, searchQuery]);

  if (articlesRequest.loading && !articlesRequest.data)
    return (
      <Transition show={true} appear={true} {...transitions.fadeSlow}>
        {Array.from({ length: 5 }).map((_, i) => (
          <ArticleThumbnailLoadingIndicator key={i} />
        ))}
      </Transition>
    );

  return (
    <Transition show={true} appear={true} {...transitions.fadeSlow}>
      {allSortedArticles.map((article) => {
        if (isDemoArticle(article)) return <DemoThumbnail key={article.id} article={article} />;

        return <ArticleThumbnail key={article.id} prefix="about/leogons/articles" onArticleDelete={articlesRequest.run} article={article} />;
      })}
    </Transition>
  );
}
