import { Tables } from '../../data/Database';
import { EditButton, useEditing } from '../Editing';
import React, { useCallback, useState } from 'react';
import { useSupabase } from '../../data/SupabaseClientProvider';
import { useSupabaseAuthContext } from '../../data/SupabaseAuthentication';
import { toast } from 'react-toastify';
import { Form } from 'react-router-dom';
import { transitions } from '../../utils/TransitionHelpers';
import { Transition } from '@headlessui/react';
import { ListInput, ListItemProps } from '../ListInput';
import { useRequest } from 'ahooks';
import { Result } from 'ahooks/lib/useRequest/src/types';
import TagPill from '../TagPill';
import _ from 'lodash';
import Article from './Article';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorPanel from '../ErrorPanel';
import MDEditor from '@uiw/react-md-editor';
import { classNames } from '../../styling/StylingUtils.ts';

export function ArticleEditor(props: {
  article: Tables<'Articles'>;
  tags: Tables<'Tags'>[];
  galleries: Tables<'ImageGallery'>[];
  onSubmit?: () => void;
}): JSX.Element {
  const { isEditing, setIsEditing } = useEditing();
  const supabase = useSupabase().getContext();
  const alltagsRequest: Result<Tables<'Tags'>[], never> = useRequest(
    async () => {
      const tagReq = await supabase.from('Tags').select();
      return tagReq.data ?? [];
    },
    {
      cacheKey: 'all-tags',
      cacheTime: 20000,
    },
  );
  const allGalleriesRequest: Result<Tables<'ImageGallery'>[], never> = useRequest(
    async () => {
      const galleryReq = await supabase.from('ImageGallery').select();
      return galleryReq.data ?? [];
    },
    {
      cacheKey: 'all-galleries',
      cacheTime: 20000,
    },
  );

  if (isEditing)
    return (
      <ErrorBoundary FallbackComponent={ErrorPanel}>
        <div className="p-10">
          <EditableArticle {...props} allGalleries={allGalleriesRequest.data} allTags={alltagsRequest.data} />
        </div>
      </ErrorBoundary>
    );

  return (
    <>
      <div className="absolute right-5 top-5 z-50 rounded-md bg-gray-900 bg-opacity-90 py-1">
        <EditButton onClick={setIsEditing} />
      </div>
      <Transition show={true} appear={true} {...transitions.basic}>
        <Article {...props} />
      </Transition>
    </>
  );
}

function EditableArticle(props: {
  /** The article itself */
  article: Tables<'Articles'>;
  /** The tags associated with the article */
  tags: Tables<'Tags'>[];
  /** Galleries associated with the article */
  galleries: Tables<'ImageGallery'>[];
  /** Callback for when the article is submitted */
  onSubmit?: () => void;
  /** Shows all existing options for tags */
  allTags?: Tables<'Tags'>[];
  /** Shows all existing options for galleries */
  allGalleries?: Tables<'ImageGallery'>[];
}): JSX.Element {
  const supabase = useSupabase().getContext();
  const { session } = useSupabaseAuthContext();
  const { article } = props;
  const { setIsEditing } = useEditing();
  const [content, setContent] = useState<string | undefined>(
    article.content ?? 'Begin typing to put words onto the screen, ya dummy',
  );
  const [tags, setTags] = useState(props.tags);
  const [galleries, setGalleries] = useState(props.galleries);

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    if (!session) {
      console.warn('User not authenticated. ');
      return;
    }

    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const title = formData.get('title');
    const description = formData.get('description');

    if (!title || !content) {
      return;
    }

    const updateArticle = await supabase
      .from('Articles')
      .update({
        title,
        description,
        content,
      })
      .match({ id: article.id })
      .single();

    const updateTags = await supabase.rpc('set_article_tags', {
      article_id_arg: article.id,
      tag_names_arg: tags.map((tag) => tag.name),
    });

    const updateGalleries = await supabase.rpc('set_article_galleries', {
      article_id_arg: article.id,
      gallery_ids_arg: galleries.map((gallery) => gallery.id),
    });

    if (updateArticle.error) toast.error('Failed to update article: ' + updateArticle.error.message);
    if (updateTags.error) toast.error('Failed to update tags: ' + updateTags.error.message);
    if (updateGalleries.error) toast.error('Failed to update galleries: ' + updateGalleries.error.message);

    if (!updateArticle.error && !updateTags.error && !updateGalleries.error) {
      toast.success('Article updated successfully.');
      setIsEditing(false);
      props.onSubmit?.();
    }
  };

  const onTagsChanged = useCallback((updatedTags: Tables<'Tags'>[]) => {
    setTags(updatedTags);
  }, []);

  return (
    <>
      <Form method="POST" onSubmit={onSubmit} className="prose-lg w-full py-3">
        <div className="absolute right-5 top-5 z-50 rounded-md bg-gray-900 bg-opacity-90 py-1">
          <div className="flex flex-row-reverse p-1">
            <EditButton />
            <button
              className="w-fit rounded-lg bg-transparent p-2 text-sm text-gray-200 hover:underline"
              onClick={() => setIsEditing(false)}
            >
              Cancel
            </button>
          </div>
        </div>
        <div className="flex w-full items-center gap-2 text-2xl font-bold text-gray-600">
          <span className="text-lg text-gray-400">#</span>
          <input
            type="text"
            name="title"
            defaultValue={article.title ?? 'Untitled'}
            className="w-full  rounded-md border-none border-transparent bg-transparent text-2xl outline-none focus:border-transparent focus:text-gray-200 focus:ring-0"
          />
        </div>
        <div className="flex w-full items-center gap-2 text-xl font-bold text-gray-600">
          <span className="text-lg text-gray-400">##</span>
          <input
            type="text"
            name="description"
            defaultValue={article.description ?? 'Untitled'}
            className="w-full  rounded-md border-none border-transparent bg-transparent text-xl outline-none focus:border-transparent focus:text-gray-200 focus:ring-0"
          />
        </div>
      </Form>
      <div className="my-2 w-full rounded-md border-2 border-gray-700 px-2 py-1">
        <ListInput
          value={tags}
          valueKey="id"
          placeholder="Add tags..."
          ListItem={(listItemProps) => (
            <TagPill
              {...listItemProps}
              actions={
                !listItemProps.isOption
                  ? [
                      (label, event) => {
                        event.stopPropagation();
                        onTagsChanged(tags.filter((tag) => tag.name !== label));
                      },
                    ]
                  : undefined
              }
            />
          )}
          onChange={onTagsChanged}
          options={props.allTags ?? []}
          filterOptions={(options, searchQuery) =>
            _.uniqBy(
              [
                ...options,
                ...tags.map((item, refIndex) => ({ item, refIndex })),
                ...(searchQuery
                  ? [
                      {
                        name: searchQuery,
                        id: (() => {
                          const minId = _.minBy(tags, (tag) => tag.id)?.id ?? 0;
                          return minId < 0 ? minId - 1 : -1;
                        })(),
                        created_at: new Date().toISOString(),
                        color: null,
                      },
                    ]
                  : props.allTags ?? []
                ).map((item, refIndex) => ({ item, refIndex })),
              ],
              (item) => item.item.id,
            )
          }
          searchSettings={{
            keys: ['name'],
          }}
        />
      </div>
      <div className="my-2 w-full rounded-md border-2 border-gray-700 px-2 py-1">
        <ListInput<Tables<'ImageGallery'>>
          value={galleries}
          onChange={setGalleries}
          valueKey="id"
          placeholder="Add galleries..."
          ListItem={ImageGalleryListItem}
          options={props.allGalleries ?? []}
          searchSettings={{
            keys: ['name'],
          }}
        />
      </div>
      <MDEditor value={content} preview="edit" onChange={setContent} />
    </>
  );
}

function ImageGalleryListItem(props: ListItemProps<Tables<'ImageGallery'>>) {
  const gallery = props.item;
  const supabase = useSupabase();
  const tagsForGallery = useRequest(
    async () => (await supabase.getContext().rpc('get_gallery_tags', { gallery_id_arg: gallery.id })).data ?? [],
    {
      cacheKey: `gallery_tags_${gallery.id}`,
      cacheTime: 60000,
      staleTime: 60000,
    },
  );

  return (
    <div
      className={classNames(
        'flex items-center justify-between gap-3',
        'rounded-md p-2 text-gray-200 transition-all duration-200 ease-in-out',
        props.isOption ? 'w-full cursor-pointer hover:bg-gray-800' : 'bg-gray-800',
        props.active ? 'bg-gray-700' : props.isOption ? 'bg-transparent' : '',
      )}
    >
      <span>{props.item.name}</span>
      <div className="flex items-center justify-end gap-1">
        {tagsForGallery.data?.map((tag: Tables<'Tags'>) => (
          <TagPill key={tag.id} hideLabel={!props.isOption} item={tag} />
        ))}
      </div>
    </div>
  );
}
