import { JSX, ReactNode } from 'react';
import Logo from '../forloopcowboy/Logo.tsx';

interface LandingPageProps {
  children: ReactNode;
}

export function LandingPage(props: LandingPageProps): JSX.Element {
  return (
    <div className="flex h-full w-full items-center justify-center">
      <div className="flex flex-col justify-center gap-10 text-white">
        <div className="flex justify-center gap-5 min-w-[90vw]">
          <div>
            <Logo className="m-auto aspect-square min-w-16 max-h-32" />
          </div>
          <div>
            <h1 className="font-riot text-4xl">
              <span>{'for'}</span>
              <span className="text-gray-700">{" { "}</span>
              <span>{'loop'}</span>
              <span className="text-gray-700">{" } "}</span>
              <span>{'cowboy'}</span>
            </h1>
            <p className="font-medium text-lg">Crafting fun with meaningful code</p>
          </div>
        </div>
        <div className="flex justify-center gap-2 md:gap-1 lg:gap-4">{props.children}</div>
      </div>
    </div>
  );
}
