import { DefaultComponents } from '../DefaultComponents.tsx';
import { useSupabaseAuthContext } from '../../data/SupabaseAuthentication.tsx';
import { useSupabase } from '../../data/SupabaseClientProvider.tsx';
import React from 'react';
import { toast } from 'react-toastify';
import { classNames } from '../../styling/StylingUtils.ts';
import MDEditor from '@uiw/react-md-editor';
import { useNavigate } from 'react-router';

export function NewContactForm() {
  const { h1: H1, h2: H2, h4: H4, a: A } = DefaultComponents;

  return (
    <>
      <H1>Contact us!</H1>
      <H2 className="text-gray-400">
        Would you like to commission some work? <br /> Ask about a project? Ask a random question? <br />
        Ponder about metaphysics?
      </H2>
      <H4 className="text-gray-600">
        Hit us up using the form below, or view your existing messages <A href="../my-messages">here</A>:
      </H4>
      <ContactForm />
    </>
  );
}

function ContactForm() {
  const { session, openAuth } = useSupabaseAuthContext();
  const { p: P } = DefaultComponents;
  const supabase = useSupabase().getContext();
  const maxLength = 1500;
  const navitate = useNavigate();

  const [content, setContent] = React.useState<string | undefined>('Type something here...');
  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;
    const formData = new FormData(form);

    const name = formData.get('name') as string;
    const email = formData.get('email') as string;
    const subject = formData.get('subject') as string;
    const message = content?.trim().slice(0, maxLength);

    const submission = await supabase.from('ContactForm').insert({ name, email, subject, message }).select().single();

    if (submission.error) {
      console.error(submission.error);
      toast.error('There was an error submitting your message. Please try again later.');
    } else {
      form.reset();
      setContent('Wanna send another one?');
      toast.success('Message sent!');
      navitate(`/contact/my-messages/${submission.data?.id}`);
    }
  };

  if (!session) {
    return (
      <div className="flex grow flex-col items-center justify-center rounded-lg bg-gray-800/50 p-24 text-center">
        <P>
          For security, spam reduction, and to be able to serve you better, we ask you to authenticate yourself in order
          to be able to contact us.
        </P>
        <P>It&apos;s quick, simple, and we will never send you emails (other than the response to this inquiry).</P>
        <button
          onClick={openAuth}
          className="text-md mb-3 w-fit rounded-lg bg-secondary-blue p-2 text-white hover:bg-secondary-blue-dark focus:bg-secondary-blue-dark"
        >
          Log in
        </button>
      </div>
    );
  }

  return (
    <form onSubmit={handleFormSubmit} className="grid gap-4 rounded-lg bg-gray-800/50 p-5 text-gray-200">
      <div className="grid">
        <label className="text-gray-400" htmlFor="name">
          Name
        </label>
        <input
          placeholder="Mr. Anderson"
          className="rounded-md border border-gray-700 bg-gray-800/50"
          type="text"
          id="name"
          name="name"
        />
      </div>
      <div className="grid">
        <label className="text-gray-400" htmlFor="email">
          Email
        </label>
        <input
          className="rounded-md border border-gray-700 bg-gray-800/50"
          placeholder="somebody@nomail.com"
          defaultValue={session.user.email}
          type="email"
          id="email"
          name="email"
        />
      </div>
      <div className="grid">
        <label className="text-gray-400" htmlFor="subject">
          Subject
        </label>
        <input
          className="rounded-md border border-gray-700 bg-gray-800/50"
          defaultValue="Random Question!"
          type="text"
          id="subject"
          name="subject"
        />
      </div>
      <div className="grid">
        <label className="text-gray-400" htmlFor="message">
          <span>Message</span>
          <span
            className={classNames(
              'ml-2 text-xs',
              (content?.length ?? 0) > maxLength
                ? 'text-red-500'
                : content?.length === maxLength
                ? 'text-yellow-500'
                : 'text-gray-400',
            )}
          >
            ( {content?.length} / {maxLength} )
          </span>
        </label>
        <MDEditor
          style={{
            backgroundColor: 'rgb(33 37 41)',
            color: 'rgb(185,191,196)',
          }}
          value={content}
          preview="edit"
          onChange={setContent}
        />
      </div>
      <button
        className={classNames(
          (content?.length ?? 0) > maxLength
            ? 'cursor-not-allowed bg-severity-danger text-severity-danger-contrasted hover:bg-severity-danger-dark'
            : 'bg-secondary-blue text-white hover:bg-secondary-blue-dark focus:bg-secondary-blue-dark',
          'rounded-md p-2 transition-colors duration-300',
        )}
        type="submit"
        disabled={content?.length === 0 || (content?.length ?? 0) > maxLength}
      >
        {(content?.length ?? 0) >= maxLength ? "Too many words man! Who's gonna be reading all that?" : 'Send'}
      </button>
    </form>
  );
}
