import { useSupabaseAuthContext } from '../../data/SupabaseAuthentication.tsx';
import { DefaultComponents } from '../DefaultComponents.tsx';
import { useSupabase } from '../../data/SupabaseClientProvider.tsx';
import { useRequest } from 'ahooks';
import { ArticleThumbnailLayout } from '../articles/ArticleThumbnail.tsx';
import React from 'react';
import Spinner from '../Spinner.tsx';
import { Helmet } from 'react-helmet';

export function MyMessages() {
  const { session, openAuth } = useSupabaseAuthContext();
  const { p: P, h1: H1, h4: H4, a: A } = DefaultComponents;
  const supabase = useSupabase().getContext();
  const allMessages = useRequest(async () => {
    const { data, error } = await supabase.from('ContactForm').select('*');
    if (error) {
      console.error(error);
      return [];
    }
    return data;
  });

  if (!session) {
    return (
      <>
        <Helmet>
          <title>My Messages</title>
          <meta name="description" content="View and edit my contact form submissions" />
        </Helmet>
        <div className="flex grow flex-col items-center justify-center rounded-lg bg-gray-800/50 p-24 text-center">
          <P>
            Before you can see your messages, we need to know who you are. Nobody likes to receive other people&apos;s
            mail, right?
          </P>
          <button
            onClick={openAuth}
            className="text-md mb-3 w-fit rounded-lg bg-secondary-blue p-2 text-white hover:bg-secondary-blue-dark focus:bg-secondary-blue-dark"
          >
            Log in
          </button>
        </div>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>My Messages</title>
        <meta name="description" content="View and edit my contact form submissions" />
      </Helmet>
      <div className="flex items-center gap-5">
        <H1>My messages</H1>
        {allMessages.loading && <Spinner className="inline" text="" />}
      </div>
      <H4 className="text-gray-600">
        You can also send us a new message <A href="..">here</A>.{' '}
      </H4>
      <div className="grid">
        {!allMessages.data?.length && !allMessages.loading && (
          <P className="py-5 text-gray-700">
            You have no messages yet. <A href="..">Write us one!</A>
          </P>
        )}
        {allMessages.data?.map((message) => (
          <ArticleThumbnailLayout
            key={message.id}
            to={`/contact/my-messages/${message.id}`}
            titleElement={<p className="text-gray-300">{message.subject}</p>}
            description={
              session.user.email !== message.email ? (
                <p className="text-gray-600">
                  <span>From </span>
                  <span className="font-semibold text-gray-500">{message.name}</span>
                  <span className="ml-2">({message.email})</span>
                </p>
              ) : (
                <p className="text-gray-600">
                  <span>From </span>
                  <span className="font-semibold text-gray-500">Me</span>
                </p>
              )
            }
            date={
              <p>
                <span>Sent on </span>
                <span className="text-gray-400">{new Date(message.created_at).toLocaleString()}</span>
              </p>
            }
          />
        ))}
      </div>
    </>
  );
}
