import { differenceInHours, formatDistanceToNow, parseISO } from 'date-fns';
import React from 'react';
import { Nullish } from './Nullish.ts';

export const defaultLocale = 'en-GB';

export function getLang() {
  if (navigator?.languages != undefined) return navigator?.languages?.[0] ?? defaultLocale;
  return navigator?.language ?? defaultLocale;
}

export function renderDateCompact(dateStr: Nullish<string>, lang?: string): string {
  if (!dateStr) return '';
  const parsed = parseISO(dateStr);
  if (lang == undefined) {
    console.log(`Lang is undefined, using '${defaultLocale}'...`);
  }

  // If distance is shorter than 6 hours, display distance
  const distance = differenceInHours(new Date(), parsed);
  if (distance < 6) {
    return formatDistanceToNow(parsed, { addSuffix: true });
  }

  // If distance longer than a year, show year
  if (distance > 24 * 365) {
    return parsed.toLocaleDateString(lang ?? defaultLocale, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  }

  return parsed.toLocaleDateString(lang ?? defaultLocale, {
    month: 'short',
    day: 'numeric',
  });
}

export function renderDateFull(dateStr: Nullish<string>, lang?: string): string {
  if (!dateStr) return '';
  const parsed = parseISO(dateStr);
  return parsed.toLocaleDateString(lang ?? defaultLocale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
}

export function renderDateTimeWithDistance(dateStr: Nullish<string>, lang?: string): string {
  if (!dateStr) return '';
  const parsed = parseISO(dateStr);

  // If distance is shorter than 12 hours, display distance
  const distance = differenceInHours(new Date(), parsed);
  if (distance < 12) {
    return formatDistanceToNow(parsed, { addSuffix: true });
  }

  return parsed.toLocaleDateString(lang ?? defaultLocale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });
}

export const LocaleContext = React.createContext(defaultLocale);

export function useLocale(): string {
  return React.useContext(LocaleContext);
}
