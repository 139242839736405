import React, { JSX, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useRequest } from 'ahooks';
import { useSupabase } from '../data/SupabaseClientProvider';
import { ArticleEditor } from '../components/articles/ArticleEditor';
import { EditingContextProvider } from '../components/Editing';
import Spinner from '../components/Spinner';
import { Helmet } from 'react-helmet';

export function ArticleView(): JSX.Element | null {
  const { articleId } = useParams();
  const supabase = useSupabase().getContext();
  const articleRequest = useRequest(
    async (id?: string) => {
      if (!id) {
        throw new Error('No article ID provided');
      }
      console.log('Fetching article ' + id);

      const articles = await supabase.from('Articles').select().eq('id', id).single();
      const tags = await supabase.rpc('get_article_tags', { article_id_arg: id });
      const galleries = await supabase.rpc('get_article_galleries', { article_id_arg: id });

      return {
        articles: articles.data,
        tags: tags.data ?? [],
        galleries: galleries.data ?? [],
      };
    },
    {
      manual: true,
      cacheKey: 'article' + articleId,
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
      staleTime: 5000,
    },
  );
  useEffect(() => {
    articleRequest.run(articleId);
  }, [articleId]);

  if (articleRequest.error) {
    return <div className="p-10 text-red-400">Error: {articleRequest.error.message}</div>;
  }

  const isLoading = articleRequest.loading || !articleRequest.data;

  if (isLoading)
    return (
      <div className="flex gap-2 p-10 min-h-64 text-gray-100">
        <Spinner className="h-5 w-5 shrink-0">Loading article...</Spinner>
      </div>
    );

  return (
    <EditingContextProvider onEditChanged={articleRequest.refresh}>
      <Helmet>
        <title>{articleRequest.data?.articles?.title ?? 'Untitled article'}</title>
        <meta
          name="description"
          content={articleRequest.data?.articles?.description ?? 'This article does not have a description.'}
        />
      </Helmet>
      <ArticleEditor
        article={articleRequest.data?.articles}
        tags={articleRequest.data?.tags ?? []}
        galleries={articleRequest.data?.galleries ?? []}
        onSubmit={() => articleRequest.refresh()}
      />
    </EditingContextProvider>
  );
}
