import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import React from 'react';

function SearchBar(props: { onSearch: (query: string) => void; targetName: string }) {
  return (
    <form
      className="group m-5 flex items-center gap-2 rounded-lg border border-gray-800 bg-gray-900 px-3 py-1 outline-blue-600 focus-within:border-blue-500 focus-within:outline"
      onSubmit={(submitEvent) => {
        submitEvent.preventDefault();
        const formData = new FormData(submitEvent.currentTarget);
        const searchQueryValue = formData.get('search_articles') as string;
        props.onSearch(searchQueryValue);
      }}
    >
      <MagnifyingGlassIcon className="aspect-square w-5 shrink-0 text-gray-200" />
      <input
        style={{
          colorScheme: 'dark',
        }}
        className="grow rounded-md border-transparent bg-transparent text-white outline-none ring-0 autofill:bg-blue-900 focus:border-transparent focus:outline-none focus:ring-0"
        type="search"
        name="search_articles"
        placeholder={`Search for ${props.targetName}...`}
      />
    </form>
  );
}

export default SearchBar;
