export enum OperatingSystem {
  WIN = 'Windows',
  MAC = 'MacOS',
  UNIX = 'UNIX',
  LINUX = 'Linux',
}

export function getOS() {
  let OSName = 'Unknown OS';

  try {
    if (navigator.appVersion.indexOf('Win') != -1) OSName = OperatingSystem.WIN;
    if (navigator.appVersion.indexOf('Mac') != -1) OSName = OperatingSystem.MAC;
    if (navigator.appVersion.indexOf('X11') != -1) OSName = OperatingSystem.UNIX;
    if (navigator.appVersion.indexOf('Linux') != -1) OSName = OperatingSystem.LINUX;
  } catch (e) {
    console.error('There was an issue getting the Operating System.');
    console.error(e);
  }

  return OSName;
}

export default function useOperatingSystem(): string {
  return getOS();
}
