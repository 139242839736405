import React, {ReactNode, useCallback, useMemo, useState} from 'react';
import ArticleThumbnail, {ArticleThumbnailLoadingIndicator} from '../components/articles/ArticleThumbnail';
import {useRequest} from 'ahooks';
import {CreateArticleButton} from '../components/articles/CreateArticleButton';
import {Transition} from '@headlessui/react';
import {transitions} from '../utils/TransitionHelpers';
import {Result} from 'ahooks/lib/useRequest/src/types';
import {ArticleInfo, GetArticlesQueryResult, useGetArticlesQuery} from '../data/articlesApi';
import HeaderLinks from './HeaderLinks';
import Spinner from '../components/Spinner';
import {DemoArticleInfo, DemoThumbnail, isDemoArticle, staticDemos} from './DemoHome.tsx';
import {parseISO} from 'date-fns';
import _ from 'lodash';
import {DefaultComponents} from '../components/DefaultComponents.tsx';
import Fuse from 'fuse.js';
import SearchBar from '../components/SearchBar.tsx';
import {Helmet} from 'react-helmet';

const gamesTags: string[] = ['Games', 'Game Dev'];

export function GamesHome() {
    const {h1: H1, h4: H2, a: A} = DefaultComponents;
    const [searchQuery, setSearchQuery] = useState('');

    const getArticles = useGetArticlesQuery(gamesTags, searchQuery);
    const articlesRequest = useRequest(getArticles, {
        refreshDeps: [searchQuery],
    });

    const allTags = useMemo(() => {
        return gamesTags.concat(
            ...(articlesRequest?.data?.tags?.map(tag => tag.name ?? '') ?? [])
        );
    }, [articlesRequest?.data?.tags]);

    return (
        <div className="flex flex-col h-full w-full">
            <Helmet>
                <title>Games</title>
                <meta name="description"
                      content={`A collection of all digital games made by or in collaboration with ForLoopCowboy and it's members.`}/>
            </Helmet>
            <HeaderLinks as="div"/>
            <div className="flex gap-10 lg:flex-row flex-col overflow-y-scroll">
                <div className="grow flex flex-col p-10">
                    <div className="flex items-stretch justify-between gap-1 md:px-0">
                        <div className="mb-2">
                            <H1>Games</H1>
                            <H2>(And other things that resemble games)</H2>
                        </div>
                        <div>
                            {articlesRequest.loading && <Spinner className="p-10" text=""/>}
                        </div>
                    </div>
                    <p className="text-gray-400 my-2">
                        {`For my entire life I have been drawn to the concept of video games. They are a multifaceted medium of communication, being capable of conveying emotion with a level of immersion no other medium can provide. `}
                    </p>
                    <p className="text-gray-400 my-2">
                        {`When I chose to join the software industry, games were, to me, the most engaging and satisfying way to express myself. I've stayed up many nights messing about with shaders and proto game-mechanics, and concocting lots of experiments and prototypes. Most of them will never see the light of day, thanks to my perfectionism, but the ones that made it through can be found here.`}
                    </p>
                    <p className="text-gray-400 my-2">
                        {`You can find my self-published games on `}
                        <A target="_blank" href="https://forloopcowboy.itch.io/">itch.io</A>
                        {` – Give them a try!`}
                    </p>
                    <p className="text-gray-400 my-2">{`Enjoy!`}</p>
                    <p className="text-gray-500 italic my-5">{`- Léo G.`}</p>
                </div>
                <div className="w-96 min-w-96 p-4 sm:w-full sm:min-h-80">
                    <div className="pt-4">
                        <CreateArticleButton onCreate={() => articlesRequest.runAsync().then(console.log)}/>
                    </div>
                    <SearchBar targetName="a game" onSearch={setSearchQuery}/>
                    <ArticleListContent articlesRequest={articlesRequest} searchQuery={searchQuery} tags={allTags}/>
                </div>
            </div>
        </div>
    );
}

export function ArticleListContent({
                                       articlesRequest,
                                       searchQuery,
                                       tags,
                                   }: {
    articlesRequest: Result<GetArticlesQueryResult, []>;
    searchQuery: string;
    tags: string[];
}) {
    const allSortedArticles: Array<ArticleInfo | DemoArticleInfo> = useMemo(() => {
        let filteredStaticDemos = searchQuery
            ? new Fuse(staticDemos, {
                keys: ['title', 'description'],
            })
                .search(searchQuery)
                .map(({item}) => item)
            : staticDemos;

        if (tags.length) {
            filteredStaticDemos = filteredStaticDemos.filter(demo => {
                const found = tags.find(tag => demo.tags.includes(tag))

                return Boolean(found);
            })
        }

        return _.sortBy(
            [
                ...(articlesRequest?.data?.articles ?? []),
                ...filteredStaticDemos.map((article) => ({...article, isStatic: true})),
            ],
            (article) => -parseISO(article.created_at ?? new Date().toISOString()).valueOf(),
        );
    }, [articlesRequest?.data?.articles, searchQuery]);

    if (articlesRequest.loading && !articlesRequest.data)
        return (
            <Transition show={true} appear={true} {...transitions.fadeSlow}>
                {Array.from({length: 5}).map((_, i) => (
                    <ArticleThumbnailLoadingIndicator key={i}/>
                ))}
            </Transition>
        );

    return (
        <Transition show={true} appear={true} {...transitions.fadeSlow}>
            {allSortedArticles.map((article) => {
                if (isDemoArticle(article)) return <DemoThumbnail key={article.id} article={article}/>;

                return <ArticleThumbnail key={article.id} onArticleDelete={articlesRequest.run} article={article}/>;
            })}
        </Transition>
    );
}
