import { DefaultComponents } from '../../components/DefaultComponents.tsx';
import { renderDateCompact, useLocale } from '../../data/Locale.ts';
import { DynamicColorEditablePill } from './DynamicColorEditablePill.tsx';
import ViewCode from '../../components/articles/ViewCode.tsx';
import useAnimatedText from '../../hooks/UseAnimatedText.ts';
import { autoColor, autoStyle } from '../../utils/ColorUtils.ts';
import { classNames } from '../../styling/StylingUtils.ts';
import { useRef } from 'react';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const pillCode = require('!!raw-loader!./DynamicColorEditablePill.tsx').default;
// eslint-disable-next-line @typescript-eslint/no-var-requires
const colorUtils = require('!!raw-loader!../../utils/ColorUtils.ts').default;

const codeText = '```tsx\n' + pillCode + '\n\n// ColorUtils.tsx\n\n' + colorUtils + '\n```';

function ColorPaletteDemo() {
  const { a: A, p: P, h1: H1, h2: H2, h3: H3, h4: H4, h5: H5, img: IMG, code: Code } = DefaultComponents;
  const locale = useLocale();
  const demoText = useRef(getRandomText()).current;
  const title = useAnimatedText({ text: 'Deterministic color randomizer', delay: 200 });

  return (
    <div className="w-full max-w-full px-10 py-3 text-white">
      <H1
        className={classNames(
          title !== 'Deterministic color randomizer' ? 'p-1 px-3 duration-100' : 'duration-2000 bg-transparent',
          'w-fit rounded-full transition-all',
        )}
        style={{
          ...(title === 'Deterministic color randomizer'
            ? {}
            : {
                ...autoStyle(autoColor(title, 7)),
              }),
        }}
      >
        {title}
      </H1>
      <H4>
        When you&apos;re too lazy to work with a proper color palette, or you want to be able to guarantee that a given
        text input will have its own specific color, this is the tool for you.
      </H4>
      <P>{renderDateCompact(new Date('2024-02-21').toISOString(), locale)}</P>
      <hr className="my-5 border border-gray-600" />
      <P>
        The inspiration for this component came from designing the <A href="/about">ForLoopCowboy about page</A>, where
        each user can have a number of roles.
      </P>
      <P>
        Instead of picking colors from the tailwindCSS palette like a normal human being, which would require me to make
        an active design choice with each role, or write a function that maps a given role to a given color, in addition
        to having to consciously pick the contrasting text color, bla, bla bla...
      </P>
      <P className="font-semibold text-blue-400">
        As the name of this website implies, I decided to pull some cowboy shit.
      </P>
      <H4>
        Introducing: the <Code>autoColor</Code> and it&apos;s ugly friend <Code>getContrastingColor</Code>
      </H4>
      <ViewCode code={codeText}>
        <div className="flex w-full flex-col items-center justify-center rounded-lg border-2 border-gray-800 p-4 md:p-10">
          <P>Try editing the text fields below 👇🏼</P>
          <div className="flex flex-wrap justify-center gap-1">
            {demoText.split(', ').map((text) => (
              <DynamicColorEditablePill key={text} initialValue={text} />
            ))}
          </div>
        </div>
      </ViewCode>
      <H2 className="my-4">FAQ</H2>
      <H3>Does it work?</H3>
      <P>
        Kinda. If you try different text inputs in this demo you will notice that some text inputs yield color
        combinations that do not contrast with one another at all. But for all of my use cases, it does.
      </P>
      <H3>Do you plan on improving it?</H3>
      <P>Not unless one of my use cases fails.</P>
      <H3>How did you come up with it?</H3>
      <P>
        A sickening mixture of laziness, cowboyism, leftover knowledge of color theory I picked up during college, and
        the all-knowing ChatGPT, who pumped out a function that barely worked, but was a good starting point.
      </P>
      <H3>
        Are you going to refactor the <Code>TagPill</Code> to use this?
      </H3>
      <P>
        It&apos;s done. Check out any <A href="/articles">article</A> and you will see!
      </P>
    </div>
  );
}

function getRandomText() {
  const val = Math.random() * 100;

  if (val < 25) return 'Web Dev, Game Dev, Graphic Designer, Musician';
  if (val < 50) return 'Hello world!, this, is, a sample, of, this stupid thing, I made';
  if (val < 65)
    return 'Example, sample, demonstration, test, trial, experiment, illustration, model, instance, case, exhibit, prototype, pattern';
  if (val < 78) return 'Poop dev, piss dev, fart dev, butt dev';
  return 'Судно (Борис Рижий), Танцевать, Фильмы, Прогноз';
}

export default ColorPaletteDemo;
