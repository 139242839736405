import { useParams } from 'react-router-dom';
import { useRequest } from 'ahooks';
import { useSupabase } from '../data/SupabaseClientProvider.tsx';
import Spinner from '../components/Spinner.tsx';
import { ErrorCard } from '../components/ErrorPanel.tsx';
import { DynamicImageElement } from '../components/images/ImageGallery.tsx';
import { TagIcon } from '@heroicons/react/24/solid';
import TagPill from '../components/TagPill.tsx';
import { Tables } from '../data/Database.ts';
import { useNavigate } from 'react-router';
import useQueryTags from '../hooks/useQueryTags.ts';

function ViewSinglePhoto() {
  const { photoId } = useParams();
  const navigate = useNavigate();
  const supabase = useSupabase().getContext();
  const photoRequest = useRequest(async () => await supabase.from('Images').select().eq('id', photoId).single());
  const tagsRequest = useRequest(
    async () => await supabase.rpc('get_image_tags', { image_id_arg: parseInt(photoId ?? '-1') }),
  );
  const tags = useQueryTags();

  if (photoRequest.loading && !photoRequest.data) {
    return <Spinner text="Loading picture..." />;
  }

  if (photoRequest.error || !photoId || !photoRequest.data) {
    return <ErrorCard error={photoRequest.error ?? { message: 'No photo ID provided.' }} />;
  }

  return (
    <div className="grid place-content-center gap-2">
      <DynamicImageElement preset="hi-def" image={photoRequest.data?.data} />
      <div className="flex items-center gap-1 text-sm text-gray-300">
        <TagIcon className="aspect-square w-4" />
        <span>Tags:</span>
        {tagsRequest.data?.data?.map((tag: Tables<'Tags'>) => (
          <TagPill
            key={tag.id}
            className="text-xs"
            item={tag}
            onClick={() => {
              navigate(`/articles`);
              tags.setTags(tag.name);
            }}
          />
        ))}
      </div>
    </div>
  );
}

export default ViewSinglePhoto;
