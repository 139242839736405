import { useEffect, useState } from 'react';

const useAnimatedText = ({ text, delay }: { text: string; delay: number }): string => {
  const [currentText, setCurrentText] = useState('');

  useEffect(() => {
    const characters = text.split('');
    let currentSequence = '';

    const updateText = (index: number) => {
      if (index < characters.length) {
        currentSequence += characters[index];
        setCurrentText((prevText) => {
          if (prevText !== currentSequence) return currentSequence;
          return prevText;
        });
      }
    };

    let index = 0;
    const interval = setInterval(() => {
      updateText(index);
      index += 1;
    }, delay);

    return () => {
      clearInterval(interval);
      currentSequence = ''; // Clear the sequence when the component is unmounted
    };
  }, [text, delay]);

  return currentText;
};

export default useAnimatedText;
