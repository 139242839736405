import { useState } from 'react';
import { autoColor, autoStyle } from '../../utils/ColorUtils.ts';

export function DynamicColorEditablePill(props: { initialValue: string }) {
  const [value, setValue] = useState(props.initialValue);

  return (
    <input
      style={{
        ...autoStyle(autoColor(value ? value : 'Default Color')),
        width: value.length + 2 + 'ch',
      }}
      className="rounded-lg border-2 p-1 text-center font-semibold"
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
}
