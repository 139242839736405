import useAnimatedText from '../../hooks/UseAnimatedText';
import { ListInput, ListItemProps } from '../../components/ListInput';
import React, { useState } from 'react';
import { useGetArticlesQuery } from '../../data/articlesApi';
import { useRequest } from 'ahooks';
import ArticleThumbnail from '../../components/articles/ArticleThumbnail';
import { classNames } from '../../styling/StylingUtils';
import ViewCode, { SignedCodeSnippet } from '../../components/articles/ViewCode';
import Modal, { ModalSizes } from '../../components/Modal';
import '../../styling/author-signature.css';
import { autoColor, autoStyle } from '../../utils/ColorUtils.ts';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const listInputCodeText = require('!!raw-loader!./ListInputCode.txt').default;
// eslint-disable-next-line @typescript-eslint/no-var-requires
const listInputSourceCodeText = require('!!raw-loader!../../components/ListInput.tsx').default;

export default function ListInputDemo() {
  const [showCode, setShowCode] = useState(false);
  const title = useAnimatedText({ text: 'ListInput.tsx', delay: 120 });

  // Emoji
  const [emoji, setEmoji] = useState<EmojiDefinition[]>([]);

  // Articles
  const getArticles = useGetArticlesQuery([]);
  const articlesRequest = useRequest(getArticles);
  const [pickedArticles, setPickedArticles] = useState<
    Omit<
      {
        author: string | null;
        content: string | null;
        created_at: string;
        description: string | null;
        id: number;
        last_modified_at: string | null;
        title: string | null;
      },
      'content'
    >[]
  >([]);

  return (
    <div className="grid gap-2 p-10 text-gray-300">
      <Modal maxWidth={ModalSizes.XXL} isOpen={showCode} closeModal={() => setShowCode(false)}>
        <SignedCodeSnippet
          title="ListInput.tsx"
          subtitle={'Feel free to copy paste! This is the full source code for the ListInput component.'}
          code={'```tsx \n' + listInputSourceCodeText + '\n```'}
        />
      </Modal>
      <h1 className="w-fit rounded-full bg-gray-950/50 p-1 px-3 text-lg text-gray-200">
        <code>{title}</code>
      </h1>
      <p>
        Need to pick items from a search bar? You bet that the{' '}
        <code className="w-fit rounded-full bg-gray-950/50 p-1 px-3 text-gray-200">ListInput</code>
        component has you covered.
      </p>
      <ViewCode code={listInputCodeText}>
        <div className="px-5 py-5 md:px-20 lg:px-40">
          <h2>Pick an emoji</h2>
          <div className="rounded-lg border border-gray-600 p-2">
            <ListInput valueKey="name" value={emoji} onChange={setEmoji} options={allEmojis} ListItem={EmojiListItem} />
          </div>
        </div>
      </ViewCode>
      <p>
        The
        <Code>ListInput</Code>
        component is written in typescript using <Code>tailwindcss</Code> and <Code>headlessui</Code>. With a little bit
        of hooking of the <Code>fuse.js</Code> library, you can search through a list of emojis and select them.
      </p>
      <p>
        Quite straight forward stuff. The fun comes in scaling. After all, depending on your dataset, the best solution
        is to filter at the source, before even sending the content to the browser, performing the query on the server.
      </p>
      <p>
        However, at the moment, this component does not support pagination, but this can be easily extended as the needs
        of the project grow.
      </p>
      <p>
        But for small datasets, this is a great way to handle the problem. And it&apos;s a great way to learn about the
        <Code>headlessui</Code> library and other components of the modern JS ecosystem.
      </p>
      <p>You can also use the ListInput to search the dynamic content of this website. Try below:</p>
      <div className="px-5 py-5 md:px-20 lg:px-40">
        <h2>Pick your favorite articles...</h2>
        <div className="rounded-lg border border-gray-600 p-2">
          <ListInput
            valueKey="id"
            value={pickedArticles}
            onChange={setPickedArticles}
            options={articlesRequest.data?.articles ?? []}
            searchSettings={{
              keys: ['title', 'description'],
            }}
            ListItem={ArticleListItem}
          />
        </div>
      </div>
      <p>Well... that&apos;s it. Stay tuned for more revolutionary code snippets and demos from the forloopcowboy.</p>
      <div className="flex justify-center py-5">
        <button
          className="bg-none px-2 py-1 text-blue-600 hover:underline focus:underline"
          onClick={() => setShowCode(true)}
        >
          View source
        </button>
      </div>
    </div>
  );
}

function Code({ children }: { children: React.ReactNode }) {
  return <code className="w-fit rounded-full bg-gray-950/50 p-1 px-3 text-gray-200">{children}</code>;
}

function EmojiListItem({ item, active, selected, isOption }: ListItemProps<EmojiDefinition>) {
  const { backgroundColor, color: textColor } = autoStyle(autoColor(item.name));

  return (
    <span
      style={{
        backgroundColor: !isOption ? backgroundColor : '',
        color: !isOption ? textColor : '',
      }}
      className={`flex items-center gap-2 text-white ${isOption ? 'w-full' : 'w-fit'} rounded-full p-2 ${
        active ? 'bg-gray-900' : ''
      } ${selected ? 'bg-gray-800' : ''}`}
    >
      <span>{item.emoji}</span>
      <span className="empty:hidden">{isOption ? item.name : ''}</span>
    </span>
  );
}

function ArticleListItem({
  item: article,
  active,
  isOption,
}: ListItemProps<
  Omit<
    {
      author: string | null;
      content: string | null;
      created_at: string;
      description: string | null;
      id: number;
      last_modified_at: string | null;
      title: string | null;
    },
    'content'
  >
>) {
  return (
    <div
      className={classNames(
        'w-full rounded-lg',
        isOption ? 'cursor-pointer' : '',
        active ? 'bg-gray-700 text-gray-200' : 'text-gray-600',
      )}
    >
      <ArticleThumbnail article={article} omitLink={isOption} />
    </div>
  );
}

const allEmojis: EmojiDefinition[] = [
  { name: 'smile', emoji: '😊' },
  { name: 'laugh', emoji: '😂' },
  { name: 'heart', emoji: '❤️' },
  { name: 'star', emoji: '⭐' },
  { name: 'fire', emoji: '🔥' },
  { name: 'rocket', emoji: '🚀' },
  { name: 'thumbs up', emoji: '👍' },
  { name: 'clap', emoji: '👏' },
  { name: 'pray', emoji: '🙏' },
  { name: 'tada', emoji: '🎉' },
  { name: 'confetti', emoji: '🎊' },
  { name: 'sparkles', emoji: '✨' },
  { name: 'rainbow', emoji: '🌈' },
  { name: 'sun', emoji: '☀️' },
  { name: 'moon', emoji: '🌙' },
  { name: 'cloud', emoji: '☁️' },
  { name: 'umbrella', emoji: '☔' },
];

interface EmojiDefinition {
  name: string;
  emoji: string;
}
