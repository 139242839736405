import React from 'react';
import ReactDOM from 'react-dom/client';
import './output.css';
import './App.css';
import reportWebVitals from './reportWebVitals';
import { RouterProvider } from 'react-router-dom';
import { appRoutes } from './routes/app-routes';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorPanel from './components/ErrorPanel';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={ErrorPanel}>
      <RouterProvider router={appRoutes} />
    </ErrorBoundary>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
