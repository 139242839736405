import TagPill from './TagPill';
import _ from 'lodash';
import { ListInput, ListInputProps } from './ListInput';
import React from 'react';
import { Tables } from '../data/Database';
import { Result } from 'ahooks/lib/useRequest/src/types';
import { useRequest } from 'ahooks';
import { useSupabase } from '../data/SupabaseClientProvider';

function TagListInput(
  props: Pick<ListInputProps<Tables<'Tags'>>, 'value' | 'onChange'> & { canCreate?: boolean; placeholder?: string },
) {
  const supabase = useSupabase().getContext();
  const alltagsRequest: Result<Tables<'Tags'>[], never> = useRequest(async () => {
    const tagReq = await supabase.from('Tags').select();
    return tagReq.data ?? [];
  });

  return (
    <ListInput
      value={props.value}
      valueKey="id"
      placeholder={props.placeholder ?? 'Add tags...'}
      placement="bottom"
      ListItem={(listItemProps) => (
        <TagPill
          {...listItemProps}
          actions={
            !listItemProps.isOption
              ? [
                  (label, event) => {
                    event.stopPropagation();
                    props.onChange(props.value.filter((tag) => tag.name !== label));
                  },
                ]
              : undefined
          }
        />
      )}
      onChange={props.onChange}
      options={alltagsRequest.data ?? []}
      filterOptions={(options, searchQuery) =>
        _.uniqBy(
          [
            ...options,
            ...props.value.map((item, refIndex) => ({ item, refIndex })),
            ...(searchQuery && props.canCreate
              ? [
                  {
                    name: searchQuery,
                    id: (() => {
                      const minId = _.minBy(props.value, (tag) => tag.id)?.id ?? 0;
                      return minId < 0 ? minId : -1;
                    })(),
                    created_at: new Date().toISOString(),
                    color: null,
                  },
                ]
              : []
            ).map((item, refIndex) => ({ item, refIndex })),
          ],
          (item) => item.item.id,
        )
      }
      searchSettings={{
        keys: ['name'],
      }}
    />
  );
}

export default TagListInput;
