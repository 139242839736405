import { Tables } from '../../data/Database.ts';
import TagListInput from '../TagListInput.tsx';
import { useRequest } from 'ahooks';
import { useSupabase } from '../../data/SupabaseClientProvider.tsx';
import React, { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { DefaultComponents } from '../DefaultComponents.tsx';
import { TrashIcon } from '@heroicons/react/24/outline';
import usePermissions from '../../hooks/UsePermission.tsx';

function EditGalleryForm<Id extends number | null>(props: { galleryId: Id; onSave: () => void }) {
  const supabase = useSupabase();
  const galleryTags = useRequest(async () =>
    props.galleryId
      ? (await supabase.getContext().rpc('get_gallery_tags', { gallery_id_arg: props.galleryId })).data ?? []
      : [],
  );
  const gallery = useRequest(
    async () => await supabase.getContext().from('ImageGallery').select('*').eq('id', props.galleryId).single(),
  );
  const { canWrite } = usePermissions();

  const [tags, setTags] = React.useState<Tables<'Tags'>[]>([]);
  const [name, setName] = React.useState<string>('');

  useEffect(() => {
    setName(gallery.data?.data?.name ?? '');
    setTags(galleryTags.data ?? []);
  }, [gallery.data, galleryTags.data]);

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (!canWrite) {
        toast.error('You do not have permission to edit galleries');
        return;
      }

      let galleryId = props.galleryId;

      if (!props.galleryId) {
        // Initialize a new gallery with the given name and tags
        const newGallery = await supabase.getContext().from('ImageGallery').insert({ name }).select('id').single();
        if (newGallery.error) return toast.error('Failed to create gallery: ' + newGallery.error.message);

        galleryId = newGallery.data?.id;
      }

      if (!galleryId) return toast.error('Could not update gallery. No gallery ID found.');

      const update = await supabase.getContext().from('ImageGallery').update({ name }).eq('id', galleryId);
      if (update.error) return toast.error('Failed to update gallery: ' + update.error.message);

      const updateTags = await supabase.getContext().rpc('set_gallery_tags', {
        gallery_id_arg: galleryId,
        tag_names_arg: tags.map((it) => it.name),
      });

      if (updateTags.error) return toast.error('Failed to update tags: ' + updateTags.error.message);

      props.onSave();
    },
    [canWrite, name, props, supabase, tags],
  );

  const handleDelete = useCallback(async () => {
    if (!props.galleryId) return;
    if (!canWrite) return toast.error('You do not have permission to delete galleries');
    if (!window.confirm(`Are you sure you want to delete "${name}" (ID::${props.galleryId})?`)) return;

    const result = await supabase.getContext().from('ImageGallery').delete().eq('id', props.galleryId);
    if (result.error) return toast.error('Failed to delete gallery: ' + result.error.message);
    else toast.success(`Gallery ${name} deleted`);

    props.onSave();
  }, [canWrite, name, props, supabase]);

  const title = props.galleryId ? 'Edit Gallery' : 'New Gallery';
  const submitBtnLabel = props.galleryId ? 'Save' : 'Create';

  return (
    <div className="text-gray-200">
      <DefaultComponents.h2>{title}</DefaultComponents.h2>
      <form className="grid gap-3" onSubmit={handleSubmit}>
        <div className="grid">
          <label className="text-gray-400" htmlFor="name">
            Name
          </label>
          <input
            className="rounded-md bg-transparent"
            type="text"
            value={name}
            disabled={gallery.loading || galleryTags.loading || !canWrite}
            onChange={(event) => setName(event.target.value)}
            id="name"
          />
        </div>
        <TagListInput value={tags} canCreate onChange={setTags} />
        <div className="grid grid-cols-[1fr_4fr] gap-2">
          {props.galleryId && canWrite && (
            <button
              style={{ borderWidth: '2px' }}
              className="border-md flex items-center justify-center gap-2 rounded-md border border-red-500 bg-red-800 bg-opacity-0 p-2 text-red-500 transition-opacity hover:bg-opacity-50"
              onClick={handleDelete}
              disabled={gallery.loading || galleryTags.loading}
              type="submit"
            >
              <TrashIcon className="h-4 w-4" />
              <span>Delete</span>
            </button>
          )}
          <button
            className="rounded-md bg-blue-500 p-2 text-white transition-colors hover:bg-blue-600 hover:text-gray-50 disabled:cursor-not-allowed disabled:line-through disabled:opacity-50"
            disabled={gallery.loading || galleryTags.loading || !canWrite}
            type="submit"
          >
            {submitBtnLabel}
          </button>
        </div>
      </form>
    </div>
  );
}

export default EditGalleryForm;
