import { Tables } from './Database';
import React from 'react';
import { useSupabase } from './SupabaseClientProvider';
import { toast } from 'react-toastify';

export type ArticleInfo = Omit<Tables<'Articles'>, 'content' | 'fts'> & {
  gallery_count: number;
};

export interface GetArticlesQueryResult {
  articles?: ArticleInfo[] | null;
  tags?: Tables<'Tags'>[] | null;
}

export function useGetArticlesQuery(searchTags: string[], searchString = '') {
  const supabaseClient = useSupabase();

  return React.useCallback(async (): Promise<GetArticlesQueryResult> => {
    const supabase = supabaseClient.getContext();
    const baseTags = await supabase.from('Tags').select().in('name', searchTags);

    if (baseTags.error) {
      toast.error(`Failed to fetch tags (${searchTags.join(', ')}): ${baseTags.error.message}`);
      return {};
    }

    let request;

    if (searchString) {
      request = await supabase
        .rpc('get_articles_by_tags', {
          tag_ids_arg: baseTags.data.map((tag) => tag.id),
        })
        .textSearch('fts', searchString, {
          type: 'websearch',
          config: 'english',
        });
    } else {
      request = await supabase
        .rpc('get_articles_by_tags', {
          tag_ids_arg: baseTags.data.map((tag) => tag.id),
        })
        .select('id, created_at, last_modified_at, title, description, gallery_count, author');
    }

    if (request.error) {
      toast.error(
        `Failed to fetch articles with tags (${baseTags.data.map(({ name }) => name).join(', ')}): ${
          request.error.message
        }`,
      );
      return {};
    }

    return {
      articles: request.data,
      tags: baseTags.data,
    };
  }, [supabaseClient, searchTags, searchString]);
}
