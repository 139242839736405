import { Form } from 'react-router-dom';
import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { useSupabase } from '../../data/SupabaseClientProvider';
import usePermissions from '../../hooks/UsePermission';

export function CreateArticleButton({ onCreate }: { onCreate?: () => void }) {
  const navigate = useNavigate();
  const supabase = useSupabase().getContext();
  const { canWrite } = usePermissions();
  const [isCreating, setIsCreating] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const onSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    async (e) => {
      e.preventDefault();
      if (!canWrite) return;

      setIsSubmitting(true);
      const form = e.currentTarget;
      const formData = new FormData(form);
      const title = formData.get('title') as string;
      const description = formData.get('description') as string;

      await supabase
        .from('Articles')
        .insert({
          title: title ? title : 'Untitled',
          description,
        })
        .select()
        .then((result) => {
          const createdArticle = result.data?.[0];

          if (result.error) {
            toast.error(`Failed to create article: ${result.error.message}`);
          } else if (createdArticle) {
            toast.info(`${title} was born!`);
            onCreate?.();
            navigate(`/articles/${createdArticle.id}?edit=true`);
          }

          setIsCreating(false);
          setIsSubmitting(false);
        });
    },
    [canWrite, supabase, onCreate, navigate],
  );

  if (!canWrite) return null;
  if (isCreating)
    return (
      <Form
        onSubmit={onSubmit}
        className="flex flex-col gap-1 rounded-md bg-gray-800 px-5 py-2 text-gray-600 transition-all"
      >
        <input
          name="title"
          placeholder="My article"
          className="text-md text-gray-400! rounded-lg border-none bg-transparent pr-2 outline-none"
        />
        <input
          name="description"
          placeholder="A new article about... something!"
          className="text-gray-100! rounded-lg border-none bg-transparent text-sm outline-none"
        />
        <div className="flex justify-end gap-2">
          <button
            className="w-fit rounded-lg bg-transparent p-2 text-sm text-gray-200 hover:underline"
            onClick={() => setIsCreating(false)}
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={isSubmitting}
            className="text-md mb-3 w-fit rounded-lg bg-secondary-blue p-2 text-white hover:bg-secondary-blue-dark focus:bg-secondary-blue-dark"
          >
            Create
          </button>
        </div>
      </Form>
    );

  return (
    <div className="p-5">
      <button
        onClick={() => setIsCreating(true)}
        className="flex w-fit justify-between rounded-lg bg-blue-500 px-4 py-2 text-left text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus-visible:ring focus-visible:ring-blue-500"
      >
        <h1 className="text-md pr-2 text-white">Create Article</h1>
      </button>
    </div>
  );
}
