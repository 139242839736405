import { PostgrestSingleResponse } from '@supabase/supabase-js';
import { Tables } from '../../data/Database';
import { UploadWidgetResult } from '@bytescale/upload-widget';
import { useSupabase } from '../../data/SupabaseClientProvider';
import React, { useCallback, useState } from 'react';
import { UploadButton } from '@bytescale/upload-widget-react';
import { ChevronUpIcon } from '@heroicons/react/24/solid';
import { Disclosure } from '@headlessui/react';
import TagListInput from '../TagListInput';
import { toast } from 'react-toastify';
import usePermissions from '../../hooks/UsePermission.tsx';

export type UploadResult = {
  /** Insertion result for images in Supabase */
  dbResult: PostgrestSingleResponse<Tables<'Images'>[]>;
  uploadWidgetResults: UploadWidgetResult[];
};

const apiKey = process.env.REACT_APP_BYTESCALE_API_KEY ?? 'UNKNOWN';

export default function UploadFiles(props: { onComplete?: (uploadResult: UploadResult) => void }) {
  const supabase = useSupabase();
  const options = {
    apiKey,
    maxFileCount: 35,
  };
  const [tags, setTags] = useState<Tables<'Tags'>[]>([]);
  const permissions = usePermissions();

  const handleFileUpload = useCallback(
    async (uploadWidgetResults: UploadWidgetResult[]): Promise<PostgrestSingleResponse<Tables<'Images'>[]>> => {
      uploadWidgetResults.forEach((uploadWidgetResult) => {
        console.log('Uploading file: ', uploadWidgetResult);
      });

      const insertImages = await supabase
        .getContext()
        .from('Images')
        .insert(
          uploadWidgetResults.map((uploadWidgetResult) => ({
            account_id: uploadWidgetResult.accountId,
            file_path: uploadWidgetResult.filePath,
            file_url: uploadWidgetResult.fileUrl,
          })),
        )
        .select();

      if (!insertImages.error) {
        const setTags = await supabase.getContext().rpc('set_bulk_image_tags', {
          image_ids_arg: insertImages.data.map((image) => image.id),
          tag_names_arg: tags.map((tag) => tag.name),
        });

        if (setTags.error) {
          toast.error('Failed to set tags: ' + setTags.error.message);
        }
      }

      props.onComplete?.({ dbResult: insertImages, uploadWidgetResults });

      return insertImages;
    },
    [props, supabase, tags],
  );

  if (!permissions.canWrite) return null;

  return (
    <Disclosure>
      {({ open }) => (
        <div>
          <Disclosure.Button className="flex w-full justify-between rounded-lg bg-blue-500 px-4 py-2 text-left text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus-visible:ring focus-visible:ring-blue-500/75">
            <span>Upload files</span>
            <ChevronUpIcon className={`${!open ? 'rotate-180 transform' : ''} h-5 w-5 text-white`} />
          </Disclosure.Button>
          <Disclosure.Panel className="grid gap-6 rounded-b-xl bg-gray-800 px-4 pb-2 pt-4 text-sm text-gray-200">
            <div>
              <span className="py-1 text-gray-400">
                Select the tags to apply to the images you are about to upload.
              </span>
              <div className="rounded-lg border-2 border-gray-600">
                <TagListInput canCreate={true} value={tags} onChange={setTags} />
              </div>
            </div>
            <UploadButton options={options} onComplete={handleFileUpload}>
              {({ onClick }) => (
                <button
                  className="flex w-fit justify-between rounded-lg bg-blue-500 px-4 py-2 text-left text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus-visible:ring focus-visible:ring-blue-500"
                  onClick={onClick}
                >
                  Upload a file...
                </button>
              )}
            </UploadButton>
          </Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  );
}
