import React, {ReactNode, useCallback, useMemo, useState} from 'react';
import ArticleThumbnail, {ArticleThumbnailLoadingIndicator} from '../components/articles/ArticleThumbnail';
import {useRequest} from 'ahooks';
import {CreateArticleButton} from '../components/articles/CreateArticleButton';
import {Transition} from '@headlessui/react';
import {transitions} from '../utils/TransitionHelpers';
import {Result} from 'ahooks/lib/useRequest/src/types';
import {ArticleInfo, GetArticlesQueryResult, useGetArticlesQuery} from '../data/articlesApi';
import HeaderLinks from './HeaderLinks';
import Spinner from '../components/Spinner';
import {DefaultComponents} from '../components/DefaultComponents.tsx';
import Fuse from 'fuse.js';
import SearchBar from '../components/SearchBar.tsx';
import {Helmet} from 'react-helmet';
import {ArticleListContent} from "./GamesHome.tsx";

const softwareTags: string[] = ['Code Portfolio', 'React'];

export function SoftwareHome() {
    const {h1: H1, h4: H2, a: A} = DefaultComponents;
    const [searchQuery, setSearchQuery] = useState('');

    const getArticles = useGetArticlesQuery(softwareTags, searchQuery);
    const articlesRequest = useRequest(getArticles, {
        refreshDeps: [searchQuery],
    });

    const allTags = useMemo(() => {
        return softwareTags.concat(
            ...(articlesRequest?.data?.tags?.map(tag => tag.name ?? '') ?? [])
        );
    }, [articlesRequest?.data?.tags]);

    return (
        <div className="flex flex-col h-full w-full">
            <Helmet>
                <title>Software</title>
                <meta name="description"
                      content={`A collection of all digital things made by or in collaboration with ForLoopCowboy and it's members.`}/>
            </Helmet>
            <HeaderLinks as="div"/>
            <div className="flex gap-10 lg:flex-row flex-col overflow-y-scroll">
                <div className="grow flex flex-col p-10">
                    <div className="flex items-stretch justify-between gap-1 md:px-0">
                        <div className="mb-2">
                            <H1>Software</H1>
                            <H2>(Code snippets, prototypes, and other miscellaneous things about software development)</H2>
                        </div>
                        <div>
                            {articlesRequest.loading && <Spinner className="p-10" text=""/>}
                        </div>
                    </div>
                    <p className="text-gray-400 my-2">
                        {`When I chose to build digital programs for a living, I did so after trying many different crafts, from woodworking to music, from metalworking to graphic design, to photography. My choice was an evolution of those experiences. I wanted to hone my passion for creation into a set of skills that would allow me to build anything.`}
                    </p>
                    <p className="text-gray-400 my-2">
                        {`I've built a diverse range of projects, from tiny speaker UIs to complex data visualization tools. I've led frontend development for a data monitoring application, contributed to backend systems, and experimented with `}
                        <A href="/games"> game mechanics and prototypes </A>
                        {`in my free time.`}
                    </p>
                    <p className="text-gray-400 my-2">{`Through these diverse experiences I've honed my skills in building complex, performant, and scalable user interfaces with React, leveraging techniques like state management with Redux, and handling complex asynchronicity using RXJS and an in-house solution. I'm proficient in crafting custom hooks to encapsulate reusable logic and streamline development, and I am at home with the component lifecycle and its quirks.`}</p>
                    <p className="text-gray-400 my-2">{`Today, I am pumped for the next project; `}<A href="/contact">contact me</A> and I will make your idea a reality!</p>
                    <p className="text-gray-400 my-2">{`On this page, I'd like to share with the world some of what I've done and learned. Enjoy!`}</p>
                    <p className="text-gray-500 italic my-5">{`- Léo G.`}</p>
                </div>
                <div className="w-96 min-w-96 p-4 sm:w-full sm:min-h-80">
                    <div className="pt-4">
                        <CreateArticleButton onCreate={() => articlesRequest.runAsync().then(console.log)}/>
                    </div>
                    <SearchBar targetName="an article" onSearch={setSearchQuery}/>
                    <ArticleListContent articlesRequest={articlesRequest} searchQuery={searchQuery} tags={allTags}/>
                </div>
            </div>
        </div>
    );
}
