import React, { JSX, useState } from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { DefaultComponents } from '../DefaultComponents';
import { Transition } from '@headlessui/react';
import { classNames } from '../../styling/StylingUtils';

/** Renders 2 tabs: One for children and other for the code string, which
 * will be rendered using Markdown syntax. */
function ViewCode({ children, code }: { children: React.ReactNode; code: string }): JSX.Element {
  const [showingTab, setShowingTab] = useState<'children' | 'code'>('children');

  return (
    <div className="overflow-scroll p-5">
      <div className="flex justify-end px-5">
        <button
          className={`w-fit rounded-lg p-2 text-gray-200 ${
            showingTab === 'children' ? 'bg-blue-800' : 'hover:underline'
          }`}
          onClick={() => setShowingTab('children')}
        >
          Component
        </button>
        <button
          className={`w-fit rounded-lg p-2 text-gray-200 ${showingTab === 'code' ? 'bg-blue-800' : 'hover:underline'}`}
          onClick={() => setShowingTab('code')}
        >
          Code
        </button>
      </div>
      <div className="rounded-lg p-2">{showingTab === 'children' ? children : <RawCode>{code}</RawCode>}</div>
    </div>
  );
}

export function SignedCodeSnippet(props: {
  title: string;
  subtitle?: string;
  code: string;
  children?: React.ReactNode;
}): JSX.Element {
  return (
    <>
      <div className="p-1 sm:p-2 md:px-4 lg:px-10">
        <DefaultComponents.h3>{props.title}</DefaultComponents.h3>
        <DefaultComponents.p className="empty:hidden">{props.subtitle}</DefaultComponents.p>
        {props.children}
      </div>
      <RawCode>{props.code}</RawCode>
      <div className="flex justify-center">
        <p className="text-gray-400">
          code by <span className="author-signature">forloopcowboy</span>
        </p>
      </div>
    </>
  );
}

export function RawCode({ children, className }: { children: string; className?: string }) {
  return (
    <Transition
      show={true}
      appear={true}
      enter="transition-opacity duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <Markdown remarkPlugins={[remarkGfm]} components={DefaultComponents} className={classNames('', className)}>
        {children}
      </Markdown>
    </Transition>
  );
}

export default ViewCode;
