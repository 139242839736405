import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { DefaultComponents } from '../DefaultComponents';
import TagPill from '../TagPill';
import { Tables } from '../../data/Database';
import useQueryTags from '../../hooks/useQueryTags';
import { Nullish } from '../../data/Nullish';
import ErrorPanel, { ErrorCard } from '../ErrorPanel';
import { useNavigate } from 'react-router';
import { ErrorBoundary } from 'react-error-boundary';
import ImageGallery from '../images/ImageGallery.tsx';
import _ from 'lodash';
import { renderDateFull, renderDateTimeWithDistance, useLocale } from '../../data/Locale.ts';
import { ArticleBody } from './ArticleBody.tsx';

function Article(props: {
  article: Nullish<Tables<'Articles'>>;
  tags: Nullish<Tables<'Tags'>[]>;
  galleries: Nullish<Tables<'ImageGallery'>[]>;
}): JSX.Element {
  const navigate = useNavigate();
  const tags = useQueryTags();
  const invalid = !props.article || !props.tags;
  const locale = useLocale();

  if (invalid) {
    return <ErrorCard error={{ message: 'Oops! Looks like the article does not exist...' }} />;
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorPanel}>
      <div className="flex flex-col items-start gap-3 p-10">
        <Markdown remarkPlugins={[remarkGfm]} components={DefaultComponents} className="w-full text-white">
          {`# ${props.article?.title ?? 'Untitled'}\n\n## ${props.article?.description}\n\n`}
        </Markdown>
        <div>
          <p className="font-medium text-gray-400">{renderDateFull(props.article?.created_at, locale)}</p>
          <p className="text-gray-500">
            (Last published {renderDateTimeWithDistance(props.article?.last_modified_at, locale)})
          </p>
        </div>
        <div className="flex flex-wrap gap-1 text-sm empty:hidden">
          {_.sortBy(props.tags, 'name').flatMap((tag) => [
            <TagPill
              key={tag.id}
              className="hidden w-fit cursor-pointer hover:underline lg:block"
              onClick={() => {
                tags.setTags(tag.name);
              }}
              item={tag}
            />,
            <TagPill
              key={tag.id + 'mobile'}
              className="block w-fit cursor-pointer hover:underline lg:hidden"
              onClick={() => {
                // ONLY NAVIGATE FOR SMALL DEVICES
                navigate(`/articles`);
                tags.setTags(tag.name);
              }}
              item={tag}
            />,
          ])}
        </div>
        <DefaultComponents.hr />
        <ArticleBody content={`${props.article?.content ?? 'Once upon a time...'}\n\n`} />
        {Boolean(props.galleries?.length) && (
          <div className="my-2 flex items-center justify-center self-stretch">
            <hr className="grow border border-gray-800" />
            <span className="text-md rounded-full bg-gray-900 px-2 py-1 text-white">Photo Galleries</span>
            <hr className="grow border border-gray-800" />
          </div>
        )}
        <div className="flex flex-wrap items-center justify-center gap-2 py-3">
          {props.galleries?.map((gallery) => (
            <ImageGallery gallery={gallery} key={gallery.id} />
          ))}
        </div>
      </div>
    </ErrorBoundary>
  );
}

export default Article;
