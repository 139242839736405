import { parseStringToArticleElements } from './ArticleParser.ts';
import { ArticleElement } from './ArticleElement.tsx';
import {
  ArticleElementRendererSettings,
  GroupedElementProps,
  renderArticleElements,
} from './ArticleElementRenderer.ts';
import { Fragment, JSX } from 'react';

export function ArticleBody(props: { content: string }): JSX.Element {
  const elements = parseStringToArticleElements(props.content);
  const renderSettings: ArticleElementRendererSettings<JSX.Element> = {
    MarkdownContent: (props) => <ArticleElement {...props} />,
    SingleSpecialElement: (props) => <ArticleElement {...props} />,
    GroupSpecialElements: function GroupSpecialElements(props: GroupedElementProps & { key: number }) {
      return (
        <div
          key={props.key}
          className="flex flex-col items-center justify-center gap-1 self-stretch px-5 py-2 md:flex-row"
        >
          {props.elements.map((element, index) => (
            <ArticleElement element={element} key={index} />
          ))}
        </div>
      );
    },
  };

  return <Fragment>{renderArticleElements(elements, renderSettings)}</Fragment>;
}
