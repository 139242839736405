import { LandingPage } from '../components/LandingPage';
import '../output.css';
import 'react-toastify/dist/ReactToastify.css';
import React from 'react';
import { Helmet } from 'react-helmet';
import {Link} from "react-router-dom";
import SquareCard from "../components/SquareCard.tsx";
import JoystickIcon from "../icons/JoystickIcon.tsx";
import SoftwareIcon from "../icons/SoftwareIcon.tsx";
import BrainIcon from "../icons/BrainIcon.tsx";

export function AppHome() {
  return (
    <div className="h-full w-full" style={{ fontFamily: 'Roboto, sans-serif', lineHeight: '1.8' }}>
      <Helmet>
        <title>Home</title>
        <meta name="description" content="A one-man software development team" />
      </Helmet>
      <LandingPage>
          <SquareCard
              title="Games"
              description="Check out some fun demos and strategy games!"
              icon={<JoystickIcon />}
              href="games"
          />
          <SquareCard
              title="Software"
              description="Read about software development."
              icon={<SoftwareIcon />}
              href="software"
          />
          <SquareCard
              title="About"
              description="Learn more about the brain behind the curtains."
              icon={<BrainIcon />}
              href="about"
          />
      </LandingPage>
    </div>
  );
}
