function Logo(props: { className: string }) {
  return (
    <svg className={props.className} xmlns="http://www.w3.org/2000/svg" viewBox="-2 -2 28 28">
      <circle cx="12" cy="12" r="12" fill="rgb(52 58 64)" strokeWidth=".25" />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="rgb(33 37 41)"
        viewBox="0 0 50 50"
        x="3"
        y="3"
        width="18"
        height="18"
      >
        <path
          stroke="#F0F0F0"
          strokeWidth=".5"
          d="M 17 1 C 10.317 1 6 4.14 6 9 L 6 18 C 6 20.381 3.9590938 22.468016 2.4960938 22.916016 C 1.6020938 23.190016 1 23.974141 1 24.869141 L 1 25.224609 C 1 26.090609 1.5756406 26.828453 2.4316406 27.064453 C 3.8876406 27.465453 6 29.587 6 32 L 6 41 C 6 45.859 10.317 49 17 49 C 17.553 49 18 48.553 18 48 L 18 46 C 18 45.447 17.553 45 17 45 C 15.558 45 14 43.471 14 41 L 14 32 C 14 29.03 11.839 26.464 9.875 25 C 11.839 23.536 14 20.97 14 18 L 14 9 C 14 6.529 15.558 5 17 5 C 17.553 5 18 4.552 18 4 L 18 2 C 18 1.448 17.553 1 17 1 z M 33 1 C 32.447 1 32 1.448 32 2 L 32 4 C 32 4.552 32.447 5 33 5 C 34.442 5 36 6.529 36 9 L 36 18 C 36 20.97 38.161 23.536 40.125 25 C 38.161 26.464 36 29.03 36 32 L 36 41 C 36 43.471 34.442 45 33 45 C 32.447 45 32 45.447 32 46 L 32 48 C 32 48.553 32.447 49 33 49 C 39.683 49 44 45.859 44 41 L 44 32 C 44 29.62 46.040906 27.531984 47.503906 27.083984 C 48.397906 26.808984 49 26.024859 49 25.130859 L 49 24.775391 C 49 23.909391 48.424359 23.170547 47.568359 22.935547 C 46.113359 22.534547 44 20.412 44 18 L 44 9 C 44 4.14 39.683 1 33 1 z"
        />
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" x="3" y="-8" viewBox="-10 -1 30 32" width="20" height="20">
        <path
          fill="#F0F0F0"
          stroke="#181818"
          transform="rotate(23)"
          d="M 12 5 C 10.428 5 9.704 6.221 9 8.5 C 8.323 10.69 7 16.644531 7 16.644531 C 7 16.644531 10.018 18 15 18 C 19.982 18 23 16.644531 23 16.644531 C 23 16.644531 21.677 10.69 21 8.5 C 20.296 6.221 19.572 5 18 5 C 16.848 5 16.274 6 15 6 C 13.726 6 13.152 5 12 5 z M 4 14 C 2.3 14 1 15.3 1 17 C 1 20.9 4.8 24 15 24 C 25.2 24 29 20.8 29 17 C 29 15.3 27.7 14 26 14 C 24.3 14 23 15.3 23 17 C 23 20.56 17.5 21 15 21 C 12.5 21 7 21 7 17 C 7 15.3 5.7 14 4 14 z"
        />
      </svg>
    </svg>
  );
}

export default Logo;
