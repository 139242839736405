import React, { useCallback } from 'react';
import { classNames } from '../styling/StylingUtils';
import usePermissions from '../hooks/UsePermission';
import { useSearchParams } from 'react-router-dom';

export interface EditingState {
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
}

const EditingContext = React.createContext<EditingState>({
  isEditing: false,
  setIsEditing: () => {
    throw new Error('EditingContextProvider not found!');
  },
});

export function useEditing(): EditingState {
  return React.useContext(EditingContext);
}

export function EditButton(props: { onClick?: (isEditing: boolean) => void }) {
  const { isEditing } = useEditing();
  const { canWrite } = usePermissions();

  if (!canWrite) {
    return null;
  }

  return (
    <button
      type="submit"
      className={classNames(
        'flex w-full justify-between rounded-lg px-4 py-2 text-left text-sm font-medium text-white focus:outline-none focus-visible:ring',
        isEditing
          ? 'bg-green-500 hover:bg-green-800 focus-visible:ring-green-500'
          : 'bg-blue-500 hover:bg-blue-800 focus-visible:ring-blue-500',
      )}
      onClick={() => {
        props.onClick?.(!isEditing);
      }}
    >
      {isEditing ? 'Save' : 'Edit'}
    </button>
  );
}

export function EditingContextProvider(props: {
  children?: React.ReactNode;
  onEditChanged?: (isEditing: boolean) => void;
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { canWrite } = usePermissions();
  const isEditing = searchParams.get('edit') === 'true' && canWrite;
  const { onEditChanged } = props;
  const setIsEditing = useCallback(
    (isEditing: boolean) => {
      setSearchParams({ edit: isEditing ? 'true' : 'false' });
      onEditChanged?.(isEditing);
    },
    [onEditChanged, setSearchParams],
  );

  return <EditingContext.Provider value={{ isEditing, setIsEditing }}>{props.children}</EditingContext.Provider>;
}
