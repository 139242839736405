import { Tables } from '../../data/Database.ts';
import { useRequest } from 'ahooks';
import { useSupabase } from '../../data/SupabaseClientProvider.tsx';
import { HeroIcon } from '../IconButton.tsx';
import { classNames } from '../../styling/StylingUtils.ts';
import TagPill, { AnyTag } from '../TagPill.tsx';
import { JSX, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { Nullish } from '../../data/Nullish.ts';
import Modal, { ModalSizes } from '../Modal.tsx';
import {
  ArrowLeftIcon,
  ArrowPathIcon,
  ArrowRightIcon,
  PlayIcon,
  PlayPauseIcon,
  TagIcon,
} from '@heroicons/react/24/solid';
import { toast } from 'react-toastify';
import * as Bytescale from '@bytescale/sdk';
import ImageWithFallback from './ImageWithFallback.tsx';
import Spinner from '../Spinner.tsx';
import { useNavigate } from 'react-router';
import useQueryTags from '../../hooks/useQueryTags.ts';
import { useMatch } from 'react-router-dom';

const IMG_API_VERSION = '6';

export type ImagePreset = 'gallery-thumbnail' | 'hi-def';

export interface ImageAction {
  name: string;
  icon: HeroIcon;
  onClick: () => void;
}

export interface DynamicImageProps {
  preset: ImagePreset;
}

export type DynamicImage = (props: DynamicImageProps) => JSX.Element;

export function DynamicImageElement(props: DynamicImageProps & { image: Tables<'Images'> }) {
  const navigate = useNavigate();
  const match = useMatch('/photos/:photoId');
  const { preset, image } = props;
  const url = Bytescale.UrlBuilder.url({
    accountId: image.account_id,
    filePath: image.file_path,
    options: {
      transformation: 'preset',
      transformationPreset: preset,
      version: IMG_API_VERSION,
    },
  });

  return (
    <ImageWithFallback
      fallback={
        preset === 'hi-def' && (
          <Spinner
            style={{
              aspectRatio: '4 / 3',
              maxHeight: '70vh',
            }}
            delay={500}
            className="grow items-center justify-center"
            text="Loading..."
          />
        )
      }
      key={image.id}
      src={url}
      alt={
        preset === 'gallery-thumbnail'
          ? 'Blurred and tiny gallery thumbnail preview tile'
          : `High definition image belonging to a gallery`
      }
      style={{
        aspectRatio: '4 / 3',
      }}
      onClick={() => (!match ? navigate(`/photos/${image.id}`) : null)}
      className={classNames(
        'max-h-[80vh]',
        !match ? 'cursor-pointer' : null,
        preset === 'hi-def' ? 'rounded-md' : null,
      )}
    />
  );
}

function ImageGallery({
  gallery,
  ...props
}: {
  gallery: Tables<'ImageGallery'>;
  actions?: Nullish<ImageAction>[];
  isEditing?: boolean;
}) {
  const supabase = useSupabase();
  const imagesFromGallery = useRequest(
    async () => {
      const result = await supabase.getContext().rpc('list_gallery_images', { gallery_id: gallery.id });

      if (result.error) throw new Error('Failed to get images from gallery: ' + result.error.message);

      return result.data;
    },
    {
      refreshDeps: [gallery.id, props.isEditing],
    },
  );
  const tagsForGallery = useRequest(
    async () => (await supabase.getContext().rpc('get_gallery_tags', { gallery_id_arg: gallery.id })).data ?? [],
    {
      refreshDeps: [gallery.id, props.isEditing],
    },
  );

  return (
    <ImageGalleryPreview
      images={
        imagesFromGallery.data?.map((image: Tables<'Images'>) => {
          return function DImg({ preset }: DynamicImageProps) {
            return <DynamicImageElement preset={preset} image={image} />;
          };
        }) ?? []
      }
      name={gallery.name}
      actions={props.actions}
      galleryTags={tagsForGallery.data}
    />
  );
}

export function ImageGalleryPreview(props: {
  name: string;
  images: DynamicImage[];
  galleryTags?: AnyTag[];
  actions?: Nullish<ImageAction>[];
}) {
  const images = props.images.slice(0, 9);
  const missingImages = 9 - (images.length ?? 0);
  const defaultImageBlocks = getDefaultImageBlocks().slice(0, missingImages);
  const [showModal, setShowModal] = useState(false);

  const actions: ImageAction[] = useMemo(
    () =>
      _.compact([
        {
          name: 'View gallery',
          icon: PlayPauseIcon,
          onClick: () => setShowModal(true),
        },
        ...(props.actions ?? []),
      ]),
    [props.actions],
  );

  return (
    <>
      <div title={props.name} className="group relative overflow-hidden rounded-md bg-gray-950 shadow-md">
        <div className="grid h-full grid-cols-3 grid-rows-[repeat(3,1fr)]">
          {images.map((Image, idx) => (
            <Image key={idx} preset="gallery-thumbnail" />
          ))}
          {defaultImageBlocks}
        </div>
        <div className="absolute left-0 top-0 flex h-full w-full items-start justify-start gap-1 stroke-black p-2 text-white">
          <span className="text-md line-clamp-1 rounded-md bg-gray-800 px-2 py-1 font-light">{props.name}</span>
        </div>
        <div className="absolute left-0 top-0 flex h-full w-full items-end justify-end gap-1 p-2">
          {props.galleryTags?.map((tag) => (
            <TagPill item={tag} key={tag.id} className="aspect-square w-4" hideLabel />
          ))}
        </div>
        <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center gap-1 opacity-100 group-hover:opacity-100 md:opacity-0">
          {actions.map((action) => (
            <button
              key={action.name}
              title={action.name}
              className="rounded-md bg-gray-900 p-1 text-gray-100 transition-colors hover:bg-gray-800 hover:text-blue-400"
              onClick={action.onClick}
            >
              <action.icon className="aspect-square h-7 shrink-0 md:h-6 lg:h-5" />
            </button>
          ))}
        </div>
      </div>
      <ImageGalleryModal
        isOpen={showModal}
        close={() => setShowModal(false)}
        name={props.name}
        images={props.images.map((Image, idx) => (
          <Image key={idx} preset="hi-def" />
        ))}
        galleryTags={props.galleryTags}
        autoplay
      />
    </>
  );
}

export function ImageGalleryModal(props: {
  /** Name of the gallery to display as modal title */
  name: string;
  /** When true, modal is open. */
  isOpen: boolean;
  /** When called should trigger closure of this modal. */
  close: () => void;
  /** Which images to display. */
  images: ReactNode[];
  /** Which tags the gallery displays */
  galleryTags?: AnyTag[];
  /** Interval between transitions in miliseconds. Defaults to 6s. */
  autoplayInterval?: number;
  /** Autoplay initial value on mount. Defaults to true. */
  autoplay?: boolean;
}) {
  const autoplayInterval = props.autoplayInterval ?? 12000;
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isCycleEnabled, setCycleEnabled] = useState(false);
  const [isAutoPlaying, setIsAutoPlaying] = useState(props.autoplay ?? true);

  const hasPrev = isCycleEnabled || currentImageIndex - 1 >= 0;
  const movePrev = useCallback(
    () =>
      hasPrev &&
      setCurrentImageIndex((prev) => {
        let newVal = prev - 1;
        if (newVal < 0) newVal = props.images.length - 1;

        return newVal;
      }),
    [props.images.length, hasPrev],
  );
  // Move prev and halt autoplay
  const forceMovePrev = useCallback(() => {
    setIsAutoPlaying(false);
    movePrev();
  }, [movePrev]);

  const hasNext = isCycleEnabled || currentImageIndex + 1 < props.images.length;
  const moveNext = useCallback(
    () => hasNext && setCurrentImageIndex((prev) => (prev + 1) % props.images.length),
    [props.images.length, hasNext],
  );
  // Move next and halt autoplay
  const forceMoveNext = useCallback(() => {
    setIsAutoPlaying(false);
    moveNext();
  }, [moveNext]);

  // Autoplay effect
  useEffect(() => {
    if (!props.isOpen) return;

    if (isAutoPlaying) {
      const id = setInterval(() => {
        if (!hasNext) setIsAutoPlaying(false);
        else moveNext();
      }, autoplayInterval);

      return () => clearInterval(id);
    }
  }, [props.isOpen, hasNext, isAutoPlaying, autoplayInterval, moveNext]);

  // Reset index when image list changes
  useEffect(() => {
    setCurrentImageIndex(0);
  }, [props.images]);

  // Register shortcuts
  useEffect(() => {
    const onArrowKeyDown = (event: KeyboardEvent) => {
      try {
        if (event.key === 'ArrowRight') {
          forceMoveNext();
        } else if (event.key === 'ArrowLeft') {
          forceMovePrev();
        }
      } catch (e) {
        toast.error('Something went wrong with the keyboard shortcut... Fire the developer');
      }
    };

    window.addEventListener<'keydown'>('keydown', onArrowKeyDown);

    return () => window.removeEventListener('keydown', onArrowKeyDown);
  }, [forceMoveNext, forceMovePrev]);

  const toggleAutoPlay = useCallback(() => {
    setIsAutoPlaying((prev) => {
      if (!prev && !hasNext) {
        // If we press play at end of gallery we restart from beginning
        setCurrentImageIndex(0);
      }

      return !prev;
    });
  }, [hasNext]);

  const navigate = useNavigate();
  const tags = useQueryTags();

  return (
    <Modal
      title={
        <div className="grid gap-2">
          <span className="text-lg font-bold text-gray-200">{props.name}</span>
          <div className="flex items-center gap-1 text-sm text-gray-300">
            <TagIcon className="aspect-square w-4" />
            <span>Tags:</span>
            {props.galleryTags?.map((tag) => (
              <TagPill
                key={tag.id}
                className="text-xs"
                item={tag}
                onClick={() => {
                  navigate(`/articles`);
                  tags.setTags(tag.name);
                }}
              />
            ))}
          </div>
        </div>
      }
      isOpen={props.isOpen}
      maxWidth={ModalSizes.XL}
      closeModal={props.close}
    >
      <div className="flex w-full items-center justify-center overflow-hidden rounded-lg empty:hidden">
        {props.images[currentImageIndex]}
        {props.images.length === 0 && <span className="text-md italic text-white">There are no images to view</span>}
      </div>
      <div className="mt-2 grid grid-cols-[1fr_auto]">
        <div className="flex items-center justify-center gap-2">
          <button
            disabled={!hasPrev}
            onClick={forceMovePrev}
            title="Previous photo"
            className="rounded-md bg-gray-900 p-2 text-white hover:bg-gray-800 hover:text-blue-400 disabled:cursor-not-allowed disabled:text-gray-500 disabled:hover:bg-gray-900 disabled:hover:text-gray-500"
          >
            <ArrowLeftIcon className="aspect-square w-4" />
          </button>
          <div className="flex items-center justify-center gap-2 text-gray-200">
            <button
              onClick={() => setCycleEnabled((prev) => !prev)}
              title="Toggle repeat photos"
              className={classNames(
                'rounded-md p-2 hover:bg-gray-800 hover:text-blue-400 disabled:cursor-not-allowed disabled:text-gray-500 disabled:hover:bg-gray-900 disabled:hover:text-gray-500',
                isCycleEnabled ? 'bg-gray-800 text-blue-500' : 'bg-gray-900 text-white',
              )}
            >
              <ArrowPathIcon className="aspect-square w-4" />
            </button>
            <span>{currentImageIndex + 1}</span>
            <span>{'/'}</span>
            <span>{props.images.length}</span>
            <button
              onClick={toggleAutoPlay}
              title="Toggle auto play"
              className={classNames(
                'rounded-md p-2 hover:bg-gray-800 hover:text-blue-400 disabled:cursor-not-allowed disabled:text-gray-500 disabled:hover:bg-gray-900 disabled:hover:text-gray-500',
                isAutoPlaying ? 'bg-gray-800 text-blue-500' : 'bg-gray-900 text-white',
              )}
            >
              <PlayIcon className="aspect-square w-4" />
            </button>
          </div>
          <button
            disabled={!hasNext}
            onClick={forceMoveNext}
            title="Next photo"
            className="rounded-md bg-gray-900 p-2 text-white hover:bg-gray-800 hover:text-blue-400 disabled:cursor-not-allowed disabled:text-gray-500 disabled:hover:bg-gray-900 disabled:hover:text-gray-500"
          >
            <ArrowRightIcon className="aspect-square w-4" />
          </button>
        </div>
      </div>
    </Modal>
  );
}

export function getDefaultImageBlocks() {
  return Array.from({ length: 9 }, (v, index) => <div key={index} className={getColorClass(index)} />);
}

function getColorClass(index: number) {
  return classNames(index % 2 === 0 ? 'bg-gray-700' : 'bg-gray-800');
}

export default ImageGallery;
