import { NavLink, useMatch } from 'react-router-dom';
import { IconButton } from '../components/IconButton';
import {
  CameraIcon,
  Cog6ToothIcon,
  CommandLineIcon,
  EnvelopeIcon,
  UserIcon,
  BookOpenIcon,
  HomeIcon,
} from '@heroicons/react/24/solid';
import React, { ElementType, Fragment } from 'react';
import { GenericComponentProps } from '../utils/GenericComponents';
import { IconClasses } from '../styling/IconClasses';
import { classNames } from '../styling/StylingUtils';
import useQueryTags from '../hooks/useQueryTags.ts';
import usePermissions from '../hooks/UsePermission.tsx';
import JoystickIcon from "../icons/JoystickIcon.tsx";
import BrainIcon from "../icons/BrainIcon.tsx";

export default function HeaderLinks<Wrapper extends ElementType>({
  as,
  size = 'MD',
  className,
  ...props
}: GenericComponentProps<Wrapper> & { size?: keyof typeof IconClasses }) {
  const { canWrite } = usePermissions();
  const Wrapper = as || Fragment;
  const wrapperProps =
    Wrapper !== Fragment
      ? {
          className: classNames(
            'flex h-fit w-full items-center justify-center pt-3 pb-8 bg-gray-900 bg-opacity-90',
            className,
          ),
          ...props,
        }
      : {};

  const queryTags = useQueryTags();
  const homeMatch = useMatch({ path: '/' });
  const aboutMatch = useMatch({ path: '/about/*' });
  const gamesMatch = useMatch({ path: '/games/*' });
  const softwareMatch = useMatch({ path: '/software/*' });
  const demosMatch = useMatch({ path: '/demos/*' });
  const articlesMatch = useMatch({ path: '/articles/*' });
  const dashboardMatch = useMatch({ path: '/dashboard/*' });
  const contactMatch = useMatch({ path: '/contact/*' });
  const photographyMatch = gamesMatch && queryTags.searchTags.map((s) => s.toLowerCase()).includes('photography');

  return (
    <Wrapper {...wrapperProps}>
      {!homeMatch && (
        <NavLink to={`/`}>
          <IconButton Icon={HomeIcon} size={size}>
            Home
          </IconButton>
        </NavLink>
      )}
      <NavLink to="../games">
        <IconButton active={Boolean(gamesMatch)} Icon={JoystickIcon} size={size}>
          Games
        </IconButton>
      </NavLink>
      <NavLink to={`../software`}>
        <IconButton active={Boolean(softwareMatch) || Boolean(demosMatch) || Boolean(articlesMatch)} Icon={CommandLineIcon} size={size}>
          Software
        </IconButton>
      </NavLink>
      <NavLink to="../about">
        <IconButton active={Boolean(aboutMatch)} Icon={BrainIcon} size={size}>
          About
        </IconButton>
      </NavLink>
      <NavLink to="../contact">
        <IconButton active={Boolean(contactMatch)} Icon={EnvelopeIcon} size={size}>
          Contact
        </IconButton>
      </NavLink>
      {canWrite && (
        <NavLink to="../dashboard">
          <IconButton active={Boolean(dashboardMatch)} Icon={Cog6ToothIcon} size={size}>
            Dashboard
          </IconButton>
        </NavLink>
      )}
    </Wrapper>
  );
}
