import { useSupabase } from '../data/SupabaseClientProvider.tsx';
import React, { useCallback, useState } from 'react';
import { Tables } from '../data/Database.ts';
import { toast } from 'react-toastify';
import { useRequest } from 'ahooks';
import { FunnelIcon, ViewfinderCircleIcon } from '@heroicons/react/24/outline';
import TagListInput from '../components/TagListInput.tsx';
import ImageList from '../components/images/ImageList.tsx';
import { DefaultComponents } from '../components/DefaultComponents.tsx';
import { useNavigate } from 'react-router';
import usePermissions from '../hooks/UsePermission.tsx';
import { ErrorCard } from '../components/ErrorPanel.tsx';
import { Helmet } from 'react-helmet';

function AllPhotos() {
  const { h1: H1, h2: H2, h3: H3, h4: H4 } = DefaultComponents;
  const navigate = useNavigate();
  const supabase = useSupabase();
  const [filterTags, setFilterTags] = useState<Tables<'Tags'>[]>([]);
  const { canWrite } = usePermissions();
  const getImages = useCallback(async (): Promise<Tables<'Images'>[]> => {
    if (!canWrite) return [];

    let response = supabase.getContext().from('Images').select().order('created_at', {
      ascending: false,
    });

    if (filterTags.length > 0) {
      const imagesWithTags = await supabase
        .getContext()
        .from('ImageTags')
        .select('image_id')
        .in(
          'tag_id',
          filterTags.map((tag) => tag.id),
        );

      if (imagesWithTags.error) toast.error('Failed to get images with tags: ' + imagesWithTags.error.message);
      else
        response = response.in(
          'id',
          imagesWithTags.data?.map((tag) => tag.image_id),
        );
    }

    const completed = await response;

    if (completed.error) {
      toast.error('Failed to get images: ' + completed.error.message);
      return [];
    }

    return completed.data ?? [];
  }, [supabase, filterTags]);
  const imagesRequest = useRequest(getImages, { refreshDeps: [filterTags] });

  if (!canWrite) {
    return (
      <>
        <Helmet>
          <title>Forbidden</title>
          <meta name="description" content="You do not have permission to view this page." />
        </Helmet>
        <ErrorCard error={{ message: 'You do not have permission to view this page.' }}>
          <button
            onClick={() => navigate('/articles?tags=Photography')}
            className="my-2 flex w-fit justify-between rounded-lg bg-blue-500 px-4 py-2 text-left text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus-visible:ring focus-visible:ring-blue-500"
          >
            View Public Photos
          </button>
        </ErrorCard>
      </>
    );
  }

  return (
    <>
      <H1>Photos</H1>
      <div className="grid gap-2">
        <div className="flex items-center rounded-lg border-2 border-gray-800 text-white">
          <div className="p-2">
            <FunnelIcon className="h-8 w-8 shrink-0 rounded-full bg-gray-800 p-2" />
          </div>
          <TagListInput value={filterTags} placeholder="Filter by tag..." onChange={setFilterTags} />
        </div>
        {imagesRequest.error && <ErrorCard error={imagesRequest.error} />}
        <ImageList
          images={imagesRequest.data ?? []}
          actions={[
            {
              title: 'View',
              onClick: (image) => {
                navigate(`/photos/${image.id}`);
              },
              icon: ViewfinderCircleIcon,
            },
          ]}
        />
      </div>
    </>
  );
}

export default AllPhotos;
