import React, { useState } from 'react';
import { classNames } from '../styling/StylingUtils';
import { isValid, parseISO } from 'date-fns';
import { getLang, renderDateCompact } from '../data/Locale';

interface DynamicFormProps<T extends object> {
  data: T;
}

function DynamicForm<T extends object>({ data }: DynamicFormProps<T>) {
  const [activeTab, setActiveTab] = useState<string>('root');

  // Get all top level primitives
  const primitives = Object.entries(data).filter(([, value]) => typeof value !== 'object');

  // Get all top level objects
  const objects = Object.entries(data).filter(([, value]) => typeof value === 'object');

  return (
    <div className="flex gap-2">
      <div className="flex w-[10rem] flex-col p-5 empty:hidden">
        {objects.length > 0 && (
          <button
            className={classNames(
              'rounded-md p-2 text-gray-dark outline-blue-600',
              activeTab === 'root' ? 'bg-blue-500 !text-white' : 'bg-white',
            )}
            onClick={() => setActiveTab('root')}
          >
            Overview
          </button>
        )}
        {objects.map(([key]) => (
          <button
            key={key}
            className={classNames(
              'rounded-md p-2 text-gray-dark outline-blue-600',
              activeTab === key ? 'bg-blue-500 !text-white' : 'bg-white',
            )}
            onClick={() => setActiveTab(key)}
          >
            {renderKey(key)}
          </button>
        ))}
      </div>
      {activeTab === 'root' ? (
        <Primitives value={primitives} />
      ) : (
        <DynamicForm data={data[activeTab as keyof T] as object} />
      )}
    </div>
  );
}

const Primitives = ({ value }: { value: [string, any][] }) => {
  return (
    <div className="flex w-full flex-col gap-1">
      {value.map(([key, value]) => (
        <div className="grid" key={key}>
          <label className="text-gray-300">{renderKey(key)}</label>
          {renderInput(key, value, '')}
        </div>
      ))}
    </div>
  );
};

const renderInput = (key: string, value: any, prefix: string) => {
  const name = prefix ? `${prefix}.${key}` : key;
  if (typeof value === 'string' && isValid(parseISO(value))) {
    return (
      <input
        disabled
        type="text"
        name={name}
        defaultValue={renderDateCompact(value, getLang())}
        className="rounded-lg border-gray-200 text-gray-dark outline-blue-600"
      />
    );
  }

  switch (typeof value) {
    case 'string':
      return (
        <input
          className="rounded-lg border-gray-200 text-gray-dark outline-blue-600"
          disabled
          type="text"
          name={name}
          defaultValue={value}
        />
      );
    case 'number':
      return (
        <input
          className="rounded-lg border-gray-200 text-gray-dark outline-blue-600"
          disabled
          type="number"
          name={name}
          defaultValue={value}
        />
      );
    case 'boolean':
      return (
        <input
          className="rounded-lg border-gray-200 text-gray-dark outline-blue-600"
          disabled
          type="checkbox"
          name={name}
          defaultChecked={value}
        />
      );
    // Add more cases for other types as needed
    default:
      return (
        <input
          className="rounded-lg border-gray-200 text-gray-dark outline-blue-600"
          disabled
          type="text"
          name={name}
          defaultValue={JSON.stringify(value)}
        />
      );
  }
};

function renderKey(key: string): string {
  return key
    .replace('_', ' ')
    .replace('_', ' ')
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, (str) => str.toUpperCase());
}

export default DynamicForm;
