import * as Bytescale from '@bytescale/sdk';
import { toast } from 'react-toastify';
import { Tables } from '../../data/Database';
import { ClipboardIcon } from '@heroicons/react/24/outline';
import { TagIcon } from '@heroicons/react/24/solid';
import React, { useCallback, useEffect } from 'react';
import Modal from '../Modal';
import { useSupabase } from '../../data/SupabaseClientProvider';
import TagListInput from '../TagListInput';
import { useRequest } from 'ahooks';
import { SupabaseClient } from '@supabase/supabase-js';
import TagPill from '../TagPill';
import Spinner from '../Spinner';
import { UserCircleIcon } from '@heroicons/react/20/solid';
import { GenericIcon, HeroIcon } from '../IconButton.tsx';

export interface ImageThumbnailAction {
  icon: HeroIcon | GenericIcon;
  title: string;
  onClick: (image: Tables<'Images'>) => void;
}

function ImageThumbnail(props: {
  image: Tables<'Images'>;
  copyButton?: boolean;
  editTags?: boolean;
  showTags?: boolean;
  actions?: ImageThumbnailAction[];
}) {
  const { image } = props;
  const [showEditTags, setShowEditTags] = React.useState(false);
  const supabase = useSupabase();
  const tagsForThisImage = useRequest(async () => {
    if (!props.showTags) return [];

    return await getTagsForImage(supabase.getContext(), image.id);
  });

  const thumbnailSrc =
    image.account_id && image.file_path
      ? Bytescale.UrlBuilder.url({
          accountId: image.account_id,
          filePath: image.file_path,
          options: {
            transformation: 'preset',
            transformationPreset: 'thumbnail',
          },
        })
      : null;
  const hiDefSrc =
    image.account_id && image.file_path
      ? Bytescale.UrlBuilder.url({
          accountId: image.account_id,
          filePath: image.file_path,
          options: {
            transformation: 'preset',
            transformationPreset: 'hi-def',
          },
        })
      : null;
  const userAvatarSrc =
    image.account_id && image.file_path
      ? Bytescale.UrlBuilder.url({
          accountId: image.account_id,
          filePath: image.file_path,
          options: {
            transformation: 'preset',
            transformationPreset: 'user-avatar',
          },
        })
      : null;

  return (
    <>
      <div className="group relative grid h-fit items-start rounded-lg border border-transparent p-1 transition-colors hover:border-blue-500">
        <img
          className="aspect-auto rounded-lg shadow-md"
          src={thumbnailSrc ?? 'https://api.dicebear.com/7.x/icons/svg?seed=P'}
          title={image.file_path ?? 'Unknown'}
          alt={image.file_path ?? 'Unknown'}
        />
        {/* Tags */}
        <div className="absolute left-0 top-0 flex h-full w-full items-end justify-end gap-1 p-2">
          {tagsForThisImage.data?.map((tag) => (
            <TagPill item={tag} key={tag.id} className="aspect-square w-2" hideLabel />
          ))}
        </div>
        {/* Actions */}
        <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center gap-1 opacity-0 group-hover:opacity-100">
          {props.actions?.map((action) => (
            <button
              key={action.title}
              title={action.title}
              className="rounded-md bg-gray-900 p-1 text-gray-100 transition-colors hover:bg-gray-900 hover:text-blue-400 focus:opacity-100"
              onClick={() => action.onClick(image)}
            >
              <action.icon className="h-4 w-4 shrink-0" />
            </button>
          ))}
          {userAvatarSrc && props.copyButton && (
            <button
              title="Copy user-avatar preset link"
              className="rounded-md bg-gray-900 p-1 text-gray-100 transition-colors hover:bg-gray-900 hover:text-blue-400 focus:opacity-100"
              onClick={() => {
                navigator.clipboard.writeText(userAvatarSrc).then(() => {
                  toast.success('Copied user avatar preset link to clipboard!');
                });
              }}
            >
              <UserCircleIcon className="h-4 w-4 shrink-0" />
            </button>
          )}
          {hiDefSrc && props.copyButton && (
            <button
              className="rounded-md bg-gray-900 p-1 text-gray-100 transition-colors hover:bg-gray-900 hover:text-blue-400 focus:opacity-100"
              onClick={() => {
                navigator.clipboard.writeText(hiDefSrc).then(() => {
                  toast.success('Copied link to clipboard!');
                });
              }}
            >
              <ClipboardIcon className="h-4 w-4 shrink-0" />
            </button>
          )}
          {props.editTags && (
            <button
              className="rounded-md bg-gray-900 p-1 text-gray-100 transition-colors hover:bg-gray-900 hover:text-blue-400 focus:opacity-100"
              onClick={() => {
                setShowEditTags(true);
              }}
            >
              <TagIcon className="h-4 w-4 shrink-0" />
            </button>
          )}
        </div>
      </div>
      {props.editTags && (
        <EditTagModal
          image={image}
          isOpen={showEditTags}
          closeModal={() => {
            tagsForThisImage.refresh();
            setShowEditTags(false);
          }}
        ></EditTagModal>
      )}
    </>
  );
}

function EditTagModal(props: { isOpen: boolean; closeModal: () => void; image: Tables<'Images'> }) {
  const supabase = useSupabase();
  const [tags, setTags] = React.useState<Tables<'Tags'>[]>([]);
  const tagsForThisImage = useRequest(
    async () => {
      if (!props.isOpen) return [];

      return await getTagsForImage(supabase.getContext(), props.image.id);
    },
    { refreshDeps: [props.isOpen] },
  );

  const onSave = useCallback(async () => {
    const addTags = await supabase.getContext().rpc('set_image_tags', {
      image_id_arg: props.image.id,
      tag_names_arg: tags.map((it) => it.name),
    });

    if (addTags.error) {
      toast.error(
        'Could not update tags. One or more errors may have occurred. Fire the developer.' + addTags.error.message,
      );
    } else {
      toast.success('Tags updated: ' + tags.map((it) => it.name).join(', '));
      props.closeModal();
    }
  }, [supabase, props, tags]);

  useEffect(() => {
    setTags(tagsForThisImage.data ?? []);
  }, [tagsForThisImage.data]);

  return (
    <Modal isOpen={props.isOpen} closeModal={props.closeModal}>
      <div className="p-4 text-white">
        <div className="flex items-center gap-2">
          <h3 className="mb-2 text-lg font-bold">Edit Tags</h3>
          {tagsForThisImage.loading && (
            <div>
              <Spinner className="p-1" text="" />
            </div>
          )}
        </div>
        <p className="mb-4 text-gray-400">Add or remove tags for this image.</p>
        <div className="my-2">
          <TagListInput value={tags} canCreate onChange={setTags} />
        </div>
        <button onClick={onSave} className="w-fit rounded-md bg-blue-500 p-2 text-gray-50">
          <span className="text-white hover:underline">Save</span>
        </button>
      </div>
    </Modal>
  );
}

async function getTagsForImage(supabase: SupabaseClient, imageId: number): Promise<Tables<'Tags'>[]> {
  const tags = await supabase.rpc('get_image_tags', { image_id_arg: imageId });
  if (tags.error) {
    toast.error('Failed to get tags for image: ' + tags.error.message);
    return [];
  }

  return tags.data ?? [];
}

export default ImageThumbnail;
