import Color from 'colorjs.io';

/** Returns a contrasting color given an initial color */
export function getContrastingColor(color: string) {
  const originalColor = new Color(color);
  const [l, c, h] = originalColor.lch;

  // Invert luminance for contrast
  let newLuminance = 100 - l;

  // Ensure difference in luminance is enough for contrast
  // FIXME: This sucks. Maybe one day we can use a better color contrast algorithm
  if (newLuminance - l < 30) {
    if (l < 50) {
      newLuminance = l + 50;
    }
    if (l > 50) {
      newLuminance = l - 50;
    }
  }

  // Adjust chroma and hue for enhanced contrast
  // Adding 180 to hue to get the complementary color
  // Adjust chroma as needed to avoid overly dull or vibrant colors
  const newChroma = Math.min(100, c + 20); // Assuming max chroma is 100

  // Update the color with new LCH values
  originalColor.set('lch.l', newLuminance);
  originalColor.set('lch.c', newChroma);

  return originalColor.toString();
}

/** Returns a color from a string value. */
export function autoColor(seed: string, bitShift = 5): string {
  // Simple hash function to convert the seed into a number
  let hash = 0;
  for (let i = 0; i < seed.length; i++) {
    const char = seed.charCodeAt(i);
    hash = (hash << bitShift) - hash + char;
    hash |= 0; // Convert to 32bit integer
  }

  // Normalize the hash into specific ranges for LCH
  const lightness = Math.abs(hash % 100); // Range for lightness is 0% to 100%
  const chroma = Math.abs(hash % 128); // Assuming a safe range for chroma
  const hue = Math.abs(hash % 360); // Range for hue is 0 to 360

  // Construct the LCH color value
  return `lch(${lightness}% ${chroma} ${hue})`;
}

/** Given the background color, outputs matching contrasting text color and distinguishing border color */
export function autoStyle(bgColor: string): {
  backgroundColor: string;
  color: string;
  borderColor: string;
} {
  const textColor = getContrastingColor(bgColor);
  const borderColor = new Color(bgColor).set('lch.l', (l) => l - 4.5).toString();

  return {
    backgroundColor: bgColor,
    color: textColor,
    borderColor,
  };
}
