import { Pill, PillAction } from './Pill';
import React, { useMemo } from 'react';
import { ListItemProps } from './ListInput';
import { classNames } from '../styling/StylingUtils';
import { Nullish } from '../data/Nullish.ts';
import { autoColor, autoStyle } from '../utils/ColorUtils.ts';

export type AnyTag = {
  id: number;
  name: Nullish<string>;
  color?: Nullish<string>;
};

function TagPill({
  item,
  active,
  ...props
}: ListItemProps<AnyTag> & {
  actions?: PillAction<Nullish<string>>[];
  onClick?: () => void;
  className?: string;
  hideLabel?: boolean;
}) {
  const isValidTag = item.id !== null && item.id > 0;
  const style = autoStyle(item.color ?? autoTagColor(item));

  return (
    <Pill<Nullish<string>>
      bgColor={style.backgroundColor}
      textColor={style.color}
      label={props.hideLabel ? '' : item.name}
      title={`${!isValidTag ? '(!!New!!) ' : ''}Tag: ${item.name}`}
      actions={props.actions}
      onClick={props.onClick}
      classname={classNames(
        'font-semibold',
        active ? 'ring-2 ring-blue-500' : '',
        props.onClick ? 'hover:underline' : '',
        props.className,
      )}
    />
  );
}

export function autoTagColor(tag: AnyTag) {
  return autoColor(tag.name ?? 'None', 3);
}

export default TagPill;
