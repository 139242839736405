import { AnyArticleElement, MarkdownElement } from './ArticleElement.tsx';

export function makeXmlTagRegEx(): RegExp {
  return /<(\w+)(.*?)>(.*?)<\/\1>/gs;
}

export function parseStringToArticleElements(input: string): AnyArticleElement[] {
  const codeBlockRegex = /```[\s\S]*?```/g;
  const tagRegex = makeXmlTagRegEx();

  let lastIndex = 0;
  const result: AnyArticleElement[] = [];
  let inCodeBlock = false;

  const processText = (text: string) => {
    if (!inCodeBlock) {
      let remainingInput = text;
      let match;
      while ((match = tagRegex.exec(remainingInput)) !== null) {
        remainingInput = processSpecialTagString(match, remainingInput, result);
      }

      // Need this double-take because tests fail without it (unless debugger is on (???))
      if (remainingInput && (match = tagRegex.exec(remainingInput)) !== null) {
        remainingInput = processSpecialTagString(match, remainingInput, result);
      }
      if (remainingInput) {
        result.push({ type: 'markdown', value: remainingInput });
      }
    } else {
      result.push({ type: 'markdown', value: text });
    }
  };

  input.replace(codeBlockRegex, (match, offset) => {
    // Process text before code block
    processText(input.substring(lastIndex, offset));

    // Add code block as markdown
    result.push({ type: 'markdown', value: match });

    lastIndex = offset + match.length;
    inCodeBlock = !inCodeBlock;
    return match;
  });

  // Process any remaining text after the last code block
  processText(input.substring(lastIndex));

  return mergeElements(result);
}

/** Given a list of elements, merge adjacent markdown elements into a single one */
export function mergeElements(elements: AnyArticleElement[]): AnyArticleElement[] {
  const mergedElements: AnyArticleElement[] = [];

  for (let i = 0; i < elements.length; i++) {
    const current = elements[i];

    // If it's a markdown element and not the last element
    if (current.type === 'markdown' && i < elements.length - 1) {
      let mergedMarkdown = current.value;

      // Merge with subsequent markdown elements
      while (i < elements.length - 1 && elements[i + 1].type === 'markdown') {
        i++;
        mergedMarkdown += (elements[i] as MarkdownElement).value;
      }

      mergedElements.push({ type: 'markdown', value: mergedMarkdown });
    } else {
      // For non-markdown or last element, just push it to the result
      mergedElements.push(current);
    }
  }

  return mergedElements;
}

/** If a regex matches, process the tag and add it to the result, returns remaining input after processing. */
function processSpecialTagString(match: RegExpExecArray, remainingInput: string, result: AnyArticleElement[]): string {
  const attrRegex = /(\w+)=["'](.*?)["']/g;
  const textBeforeTag = remainingInput.substring(0, match.index);
  if (textBeforeTag) {
    result.push({ type: 'markdown', value: textBeforeTag });
  }

  const [, tag, rawAttrs, children] = match;
  const props: Record<string, string> = {};

  let attrMatch;
  while ((attrMatch = attrRegex.exec(rawAttrs)) !== null) {
    const [, key, value] = attrMatch;
    props[key] = value;
  }

  result.push({
    type: 'special',
    tag: tag,
    children: children,
    props: props,
  });

  return remainingInput.substring(match.index + match[0].length);
}
