import React, { useState } from 'react';

type ImageWithFallbackProps = React.ComponentPropsWithoutRef<'img'> & {
  fallback: React.ReactNode;
};

const ImageWithFallback: React.FC<ImageWithFallbackProps> = ({ fallback, ...imgProps }) => {
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoaded = () => {
    setIsLoading(false);
  };

  const handleImageError = () => {
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && fallback}
      <img
        alt="Unknown"
        {...imgProps}
        onLoad={handleImageLoaded}
        onError={handleImageError}
        style={{ display: isLoading ? 'none' : 'inline' }}
      />
    </>
  );
};

export default ImageWithFallback;
