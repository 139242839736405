import HeaderLinks from './HeaderLinks.tsx';
import React, { useCallback, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function PhotosHome() {
  return (
    <div className={'flex h-full w-full flex-col overflow-y-auto'}>
      <Helmet>
        <title>Photos</title>
      </Helmet>
      <HeaderLinks as="div" />
      <div className="p-5 lg:px-24">
        <Outlet />
      </div>
    </div>
  );
}

export default PhotosHome;
