import { AnyArticleElement, ArticleElementProps, MarkdownElement, SpecialElement } from './ArticleElement.tsx';

export interface GroupedElementProps {
  elements: SpecialElement[];
}

export interface ArticleElementRendererSettings<TOut> {
  MarkdownContent: (props: ArticleElementProps<MarkdownElement> & { key: number }) => TOut;
  SingleSpecialElement: (props: ArticleElementProps<SpecialElement> & { key: number }) => TOut;
  GroupSpecialElements: (props: GroupedElementProps & { key: number }) => TOut;
}

export function renderArticleElements<TOut>(
  elements: AnyArticleElement[],
  settings: ArticleElementRendererSettings<TOut>,
): TOut[] {
  let key = 0;
  const renderedElements: TOut[] = [];
  let specialElementsBuffer: SpecialElement[] = [];

  const flushSpecialElementsBuffer = () => {
    if (specialElementsBuffer.length > 0) {
      renderedElements.push(
        specialElementsBuffer.length === 1
          ? settings.SingleSpecialElement({ key, element: specialElementsBuffer[0] })
          : settings.GroupSpecialElements({ key, elements: [...specialElementsBuffer] }),
      );
      key++;
      specialElementsBuffer = [];
    }
  };

  elements.forEach((element) => {
    if (element.type === 'markdown') {
      flushSpecialElementsBuffer();
      renderedElements.push(settings.MarkdownContent({ key, element }));
      key++;
    } else {
      specialElementsBuffer.push(element);
    }
  });

  flushSpecialElementsBuffer();

  return renderedElements;
}
