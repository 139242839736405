import { DefaultComponents } from '../../components/DefaultComponents';
import LoadingIndicator from '../../components/LoadingIndicator';
import { useState } from 'react';
import { classNames } from '../../styling/StylingUtils';
import { renderDateCompact, useLocale } from '../../data/Locale.ts';

function MapPollDemo() {
  const { p: P, h1: H1, h4: H4, a: A } = DefaultComponents;
  const [isLoading, setIsLoading] = useState(true);
  const locale = useLocale();

  return (
    <div className="w-full px-10 py-3 text-white">
      <H1>WPI Through Many Eyes</H1>
      <H4>A heatmap visualization of the Worcester Polytechnic Institute campus.</H4>
      <P>{renderDateCompact(new Date('Feb 26, 2020').toISOString(), locale)}</P>
      <hr className="my-5 border border-gray-600" />
      <P>
        This web application is the last iteration of a series of projects designed for the WPI Data Visualization
        class, taught by{' '}
        <A target="_blank" href="https://www.wpi.edu/people/faculty/ltharrison">
          Lane T. Harrison, Ph.D
        </A>
        , and my idea was to collect a lot of opinions from students about the WPI campus in order to, quite literally,
        draw a picture of what the general opinion of the campus is.
      </P>
      <LoadingIndicator isLoading={isLoading} as="div" className="my-10 aspect-video w-full rounded-lg">
        <iframe
          onLoad={() => {
            setTimeout(() => setIsLoading(false), 150);
          }}
          title="wpi-map-poll-vis"
          className={classNames(
            'aspect-video w-full rounded-lg transition-opacity',
            isLoading ? 'opacity-0' : 'opacity-100',
          )}
          src="https://lcgonsalves.github.io/WPI-Map-Poll/#/vis"
        >
          <p>Your browser does not support iframes.</p>
        </iframe>
      </LoadingIndicator>
      <P>
        This application was built as an opportunity to learn to code{' '}
        <A target="_blank" href="https://reactjs.org/">
          React
        </A>
        , attempting the grand task of merging the front end niceties of React, while also harnessing the power of D3 to
        generate a visualization of a custom-made GeoJSON of the WPI campus.
      </P>
      <P>
        However, the poll was not completed by many students, so the data is not representative of the student body. And
        due to time constraints, you bet there&apos;s some questionable code in there, as well as some interesting
        choices of color for some elements. But it was a fun project to work on!
      </P>
      <P>
        You can view the source code for the project{' '}
        <A target="_blank" href="https://github.com/lcgonsalves/WPI-Map-Poll/">
          here
        </A>
        .
      </P>
    </div>
  );
}

export default MapPollDemo;
