import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import dark from 'react-syntax-highlighter/dist/esm/styles/prism/atom-dark';
import { classNames } from '../styling/StylingUtils';
import { Link } from 'react-router-dom';
import React from 'react';
import Spinner from './Spinner.tsx';
import ImageWithFallback from './images/ImageWithFallback.tsx';

export const buildDefaultComponents = (className = '') => ({
  h1: (props: any) => (
    <h1 {...props} className={classNames('my-5 text-3xl font-bold text-gray-100', props.className, className)} />
  ),
  h2: (props: any) => (
    <h2 {...props} className={classNames('my-3 text-2xl font-medium text-gray-300', props.className, className)} />
  ),
  h3: (props: any) => (
    <h3 {...props} className={classNames('my-5 text-xl font-bold italic text-gray-300', props.className, className)} />
  ),
  h4: (props: any) => (
    <h4 {...props} className={classNames('my-5 text-lg font-medium text-gray-300', props.className, className)} />
  ),
  h5: (props: any) => (
    <h5 {...props} className={classNames('text-md my-5 font-medium text-gray-400', props.className, className)} />
  ),
  h6: (props: any) => (
    <h6 {...props} className={classNames('my-5 text-sm font-bold text-gray-400', props.className, className)} />
  ),
  p: (props: any) => {
    if (typeof props.children !== 'string' && !Array.isArray(props.children)) return <>{props.children}</>;
    return <p {...props} className={classNames('mb-5 text-gray-200', props.className, className)} />;
  },
  a: (props: React.ComponentPropsWithoutRef<'a'>) => {
    // If our link refers to a local page, use the Link component from the router
    // Local links either start w / or . or not https
    if (
      props.href?.startsWith('/') ||
      props.href?.startsWith('.') ||
      !props.href?.startsWith('https') ||
      !props.href?.includes('://')
    )
      return (
        <Link
          {...props}
          to={props.href ?? '.'}
          className={classNames(
            'text-blue-500 visited:text-secondary-blue hover:underline',
            props.className,
            className,
          )}
        />
      );

    return (
      <a
        {...props}
        target="_blank"
        className={classNames('text-blue-500 visited:text-secondary-blue hover:underline', props.className, className)}
      />
    );
  },
  ul: (props: any) => (
    <ul {...props} className={classNames('mb-3 list-inside list-disc', props.className, className)} />
  ),
  ol: (props: any) => (
    <ol {...props} className={classNames('mb-3 list-inside list-decimal text-gray-200', props.className, className)} />
  ),
  li: (props: any) => (
    <li {...props} className={classNames('text-gray-500 marker:text-blue-300', props.className, className)}>
      {typeof props.children === 'string' ? capitalizeFirstCharacter(props.children) : props.children}
    </li>
  ),
  blockquote: (props: any) => (
    <blockquote
      {...props}
      className={classNames('mb-3 border-l-4 border-gray-400 pl-2 text-gray-200', props.className, className)}
    />
  ),
  hr: (props: any) => (
    <hr {...props} className={classNames('my-5 self-stretch border border-gray-800', props.className, className)} />
  ),
  table: (props: any) => (
    <div className="my-5 overflow-hidden rounded-md border border-gray-lightest md:rounded-lg">
      <table className={classNames('min-w-full table-auto border-collapse', props.className, className)} {...props} />
    </div>
  ),
  thead: (props: any) => (
    <thead className={classNames('bg-gray-700 text-gray-300', props.className, className)} {...props} />
  ),
  tbody: (props: any) => <tbody className={classNames('text-gray-200', props.className, className)} {...props} />,
  th: (props: any) => <th className={classNames('px-3 py-2 text-left', props.className, className)} {...props} />,
  td: (props: any) => (
    <td className={classNames('border border-gray-700 px-3 py-2 text-left', props.className, className)} {...props} />
  ),
  img: (props: any) => (
    <pre className="p-1 md:px-2 lg:px-24">
      <ImageWithFallback
        fallback={
          <Spinner
            style={{
              aspectRatio: '4 / 3',
              maxHeight: '70vh',
            }}
            delay={500}
            className="grow items-center justify-center"
            text="Loading photo..."
          />
        }
        {...props}
        alt="Image in the article."
        style={{
          ...(props.style ?? []),
          aspectRatio: '4 / 3',
        }}
        className={classNames('max-h-[80vh] rounded-lg', props.className)}
      />
    </pre>
  ),
  code(props: any) {
    const { children, className, node, ...rest } = props;
    const match = /language-(\w+)/.exec(className || '');
    return match ? (
      <SyntaxHighlighter
        {...rest}
        PreTag="div"
        customStyle={{
          margin: undefined,
          lineBreak: 'break-word',
          maxWidth: '95%',
        }}
        children={String(children).replace(/\n$/, '')}
        language={match[1]}
        style={dark}
      />
    ) : (
      <code {...rest} className={classNames('rounded-lg bg-gray-700/50 px-2 py-1 text-gray-200', className)}>
        {children}
      </code>
    );
  },
  pre(props: any) {
    return <pre {...props} className={classNames('my-5 p-1 text-sm sm:p-2 md:p-4 xl:p-10', props.className)} />;
  },
});

function capitalizeFirstCharacter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const DefaultComponents = buildDefaultComponents();
