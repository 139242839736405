console.log('Initiating Supabase...');

const url = process.env.SUPABASE_URL ?? process.env.REACT_APP_SUPABASE_URL;
const key = process.env.SUPABASE_KEY ?? process.env.REACT_APP_SUPABASE_KEY;

if (!url || !key) throw new Error('Supabase URL or Key not found! Cannot initialize!');

export const supabaseUrl = url;
export const supabaseKey = key;

console.log({ supabaseUrl, supabaseKey });
