import React, { ElementType, JSX, ReactNode, useCallback } from 'react';
import { renderDateCompact, useLocale } from '../../data/Locale';
import { Tables } from '../../data/Database';
import { classNames } from '../../styling/StylingUtils';
import usePermissions from '../../hooks/UsePermission';
import { useSupabase } from '../../data/SupabaseClientProvider';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { NavLink, useMatch, useSearchParams } from 'react-router-dom';
import { GenericComponentProps } from '../../utils/GenericComponents';
import LoadingIndicator from '../LoadingIndicator';
import { ArticleInfo } from '../../data/articlesApi.ts';
import { PhotoIcon } from '@heroicons/react/16/solid';

function ArticleThumbnail(props: {
  article: Partial<ArticleInfo>;
  onArticleDelete?: () => void;
  omitLink?: boolean;
  prefix?: string;
}): JSX.Element {
  const { article } = props;
  const locale = useLocale();
  const [searchParams] = useSearchParams();
  const queryParams = searchParams.toString() ? `?${searchParams.toString()}` : '';

  const as = props.omitLink ? 'div' : NavLink;
  const to = `/${props.prefix ?? 'articles'}/${article.id}${queryParams}`;
  const match = useMatch({ path: `/articles/${article.id}` });

  const baseTitle = article.title ? article.title : 'Untitled';
  const icon = props.article.gallery_count ? (
    <PhotoIcon
      className={classNames(
        'mr-1 inline aspect-square w-4 shrink-0 align-sub',
        match ? 'text-blue-400' : 'text-gray-400',
      )}
    />
  ) : null;
  const titleElement = icon ? (
    <div className="text-md line-clamp-2 pr-2 text-gray-400">
      {icon}
      {baseTitle}
    </div>
  ) : (
    <span className="text-md line-clamp-2 pr-2 text-gray-400">{baseTitle}</span>
  );

  return (
    <ArticleThumbnailLayout
      titleElement={titleElement}
      description={<p className="line-clamp-2 text-sm empty:hidden md:block">{article.description}</p>}
      date={
        <p className="text-right text-xs text-gray-500 empty:hidden">{renderDateCompact(article.created_at, locale)}</p>
      }
      as={as}
      to={props.omitLink ? undefined : to}
    >
      {props.onArticleDelete && <DeleteArticleButton onDelete={props.onArticleDelete} article={article} />}
    </ArticleThumbnailLayout>
  );
}

export function ArticleThumbnailLoadingIndicator(): JSX.Element {
  const randomLengthTitle = Math.random() > 0.5 ? 'Loading' : 'Loading long title';

  return (
    <ArticleThumbnailLayout
      as="div"
      titleElement={
        <div>
          <LoadingIndicator isLoading={true} as="h2" className="line-clamp-1 pr-2 text-lg text-gray-400">
            {randomLengthTitle}
          </LoadingIndicator>
        </div>
      }
      description={
        <LoadingIndicator isLoading={true} as="p" className="text-sm empty:hidden md:block">
          {'Loading description'}
        </LoadingIndicator>
      }
      date={
        <div className="flex justify-end">
          <LoadingIndicator isLoading={true} as="span" className="text-right text-xs text-gray-500">
            {renderDateCompact(new Date().toISOString(), 'en-US')}
          </LoadingIndicator>
        </div>
      }
    />
  );
}

/**
 * HMTL layout for an article thumbnail.
 */
export function ArticleThumbnailLayout<E extends ElementType>({
  as,
  date,
  description,
  children,
  titleElement,
  ...props
}: GenericComponentProps<E> & {
  titleElement?: ReactNode;
  description?: ReactNode;
  date?: ReactNode;
  children?: React.ReactNode;
}): JSX.Element {
  const Main: ElementType = as ?? NavLink;
  const defaultClassName =
    'flex flex-col focus:outline-none focus-visible:ring focus-visible:ring-blue-500/75 gap-1 rounded-md p-5 duration-300 transition-all hover:bg-gray-800';
  const componentProps =
    Main === NavLink
      ? {
          ...props,
          className: ({ isActive }: { isActive: true }) =>
            classNames(
              'border',
              defaultClassName,
              isActive ? 'bg-gray-800 border-blue-500 text-gray-600' : 'border-transparent text-gray-600',
            ),
        }
      : {
          ...props,
          className: classNames(defaultClassName, props.className),
        };

  return (
    <div className="group relative">
      <Main {...componentProps}>
        <div className="grid grid-cols-[2fr_1fr] gap-2">
          {titleElement}
          {date}
        </div>
        {description}
      </Main>
      {children}
    </div>
  );
}

function DeleteArticleButton(props: {
  article: Partial<Tables<'Articles'>>;
  onDelete?: () => void;
}): JSX.Element | null {
  const { canWrite } = usePermissions();
  const supabase = useSupabase();
  const navigate = useNavigate();

  const handleDelete = useCallback(() => {
    if (!props.article.id) {
      toast.warn('Article ID is missing. Cannot delete article without an ID. Fire the developer!', {});
      return;
    }

    if (
      !canWrite ||
      !window.confirm(`Are you sure you want to delete "${props.article.title}" (ID::${props.article.id})?`)
    )
      return;

    supabase
      .getContext()
      .from('Articles')
      .delete()
      .match({ id: props.article.id })
      .select()
      .then((fulfilled) => {
        if (fulfilled.error) {
          toast.error(`Failed to delete article: ${fulfilled.error.message}`);
        } else {
          navigate('/articles');
          props.onDelete?.();
          toast.info(`${fulfilled.data?.length ?? 0} Article${fulfilled.data?.length > 1 ? 's' : ''} deleted`);
        }
      });
  }, [canWrite, supabase, props.article.id, props.onDelete, navigate]);

  if (!canWrite) return null;

  return (
    <button
      onClick={handleDelete}
      className="absolute bottom-1/2 right-2 top-1/2 h-fit w-fit rounded-full bg-transparent opacity-0 hover:bg-gray-700 focus:opacity-100 group-hover:opacity-100"
    >
      <XMarkIcon className="h-4 w-4 shrink-0 text-red-600 hover:text-red-400" />
    </button>
  );
}

export default ArticleThumbnail;
