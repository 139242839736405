import ImageGallery from './ImageGallery.tsx';
import { useRequest } from 'ahooks';
import { useSupabase } from '../../data/SupabaseClientProvider.tsx';
import { GenericComponentProps } from '../../utils/GenericComponents.ts';
import { ElementType, useState } from 'react';
import { classNames } from '../../styling/StylingUtils.ts';
import { GALLERY_CLS } from './ImageList.tsx';
import { PencilIcon } from '@heroicons/react/24/outline';
import Modal, { ModalSizes } from '../Modal.tsx';
import EditGalleryForm from './EditGalleryForm.tsx';
import usePermissions from '../../hooks/UsePermission.tsx';

function ImageGalleryList<E extends ElementType>(props: GenericComponentProps<E>) {
  const supabase = useSupabase();
  const [editingGalleryId, setEditingGalleryId] = useState<number | null>(null);
  const allGalleries = useRequest(async () => await supabase.getContext().from('ImageGallery').select('*'));
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const { canWrite } = usePermissions();

  const Element = props.as || 'div';
  const elementProps = {
    ...props,
    className: classNames(GALLERY_CLS, props.className),
  };

  return (
    <>
      <div className="empty:hidden">
        {canWrite && (
          <button
            className="flex w-fit justify-between rounded-lg bg-blue-500 px-4 py-2 text-left text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus-visible:ring focus-visible:ring-blue-500"
            onClick={() => {
              setIsEditorOpen(true);
              setEditingGalleryId(null);
            }}
          >
            New Gallery
          </button>
        )}
      </div>
      <Element {...elementProps}>
        {allGalleries.data?.data?.map((gallery) => (
          <ImageGallery
            key={gallery.id}
            isEditing={editingGalleryId === gallery.id}
            gallery={gallery}
            actions={[
              canWrite
                ? {
                    name: 'Edit',
                    icon: PencilIcon,
                    onClick: () => {
                      setEditingGalleryId(gallery.id);
                      setIsEditorOpen(true);
                    },
                  }
                : null,
            ]}
          />
        ))}
      </Element>
      <Modal maxWidth={ModalSizes.SM} isOpen={isEditorOpen} closeModal={() => setIsEditorOpen(false)}>
        <EditGalleryForm
          galleryId={editingGalleryId}
          onSave={() => {
            allGalleries.refresh();
            setEditingGalleryId(null);
            setIsEditorOpen(false);
          }}
        />
      </Modal>
    </>
  );
}

export default ImageGalleryList;
