// STAB_UNKNOWN
import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { MouseEvent } from 'react';
import { classNames } from '../styling/StylingUtils';
import Color from 'colorjs.io';
import { Nullish } from '../data/Nullish.ts';
import { autoStyle, getContrastingColor } from '../utils/ColorUtils.ts';

export type PillAction<Label = any> = (label: Label, event: MouseEvent<HTMLButtonElement>) => void;

export interface PillProps<Label> {
  label: Label;
  actions?: PillAction<Label>[];
  bgColor?: string;
  textColor?: string;
  classname?: string;
  onClick?: () => void;
  title?: string;
}

export function Pill<Label extends Nullish<string> = Nullish<string>>({
  label,
  actions,
  classname,
  onClick,
  ...props
}: PillProps<Label>): JSX.Element {
  const Container = onClick ? 'button' : 'div';
  const style = autoStyle(props.bgColor ?? '#9f4fff');

  return (
    <Container
      style={{
        borderWidth: '1px',
        ...style,
        color: props.textColor ?? style.color,
      }}
      onClick={onClick}
      role={onClick ? 'button' : undefined}
      title={props.title}
      className={classNames(`flex h-fit w-fit gap-1 whitespace-nowrap rounded-md px-1.5 py-1 shadow-sm`, classname)}
    >
      <span className="grow text-center empty:hidden">{label}</span>
      {actions?.map((action, idx) => (
        <button
          key={idx}
          onClick={(evt) => {
            action(label, evt);
          }}
          className="cursor-pointer rounded-full bg-transparent p-1 transition-all hover:!bg-red-600 disabled:cursor-not-allowed"
        >
          <XMarkIcon className="h-3 w-4 text-white" />
        </button>
      ))}
    </Container>
  );
}
