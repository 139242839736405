import HeaderLinks from './HeaderLinks.tsx';
import React from 'react';
import { Helmet } from 'react-helmet';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const privacyText = require('!!raw-loader!../data/PrivacyNotice.html').default;

export default function PrivacyNotice() {
  return (
    <div className="max-h-[100vh] p-6">
      <Helmet>
        <title>Privacy notice</title>
      </Helmet>
      <HeaderLinks as="div" />
      <div
        className="max-h-[80vh] overflow-auto rounded-md border-2 border-gray-600 bg-white p-5"
        dangerouslySetInnerHTML={{ __html: privacyText }}
      />
    </div>
  );
}
