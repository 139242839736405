import ImageThumbnail, { ImageThumbnailAction } from './ImageThumbnail';
import { Tables } from '../../data/Database';
import usePermissions from '../../hooks/UsePermission.tsx';

export default function ImageList({
  images,
  actions,
}: {
  images: Tables<'Images'>[];
  actions?: ImageThumbnailAction[];
}) {
  const { canWrite } = usePermissions();

  return (
    <div className={GALLERY_CLS}>
      {images.map((image) => (
        <ImageThumbnail
          image={image}
          key={image.id}
          showTags
          actions={actions}
          copyButton={canWrite}
          editTags={canWrite}
        />
      ))}
    </div>
  );
}

export const GALLERY_CLS = 'grid grid-cols-3 gap-2 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6';
