import UploadFiles from './UploadFiles.tsx';
import { toast } from 'react-toastify';
import { DefaultComponents } from '../DefaultComponents.tsx';
import ImageGalleryList from './ImageGalleryList.tsx';
import ImageList from './ImageList.tsx';
import React, { useCallback, useState } from 'react';
import { Tables } from '../../data/Database.ts';
import { useRequest } from 'ahooks';
import { FunnelIcon } from '@heroicons/react/24/outline';
import TagListInput from '../TagListInput.tsx';
import { useSupabase } from '../../data/SupabaseClientProvider.tsx';

/** Component for uploading images, creating and editing galleries, and viewing images and searching by tag. */
function GalleryEditor() {
  const supabase = useSupabase();
  const [filterTags, setFilterTags] = useState<Tables<'Tags'>[]>([]);
  const getImages = useCallback(async (): Promise<Tables<'Images'>[]> => {
    let response = supabase.getContext().from('Images').select().order('created_at', {
      ascending: false,
    });

    if (filterTags.length > 0) {
      const imagesWithTags = await supabase
        .getContext()
        .from('ImageTags')
        .select('image_id')
        .in(
          'tag_id',
          filterTags.map((tag) => tag.id),
        );

      if (imagesWithTags.error) toast.error('Failed to get images with tags: ' + imagesWithTags.error.message);
      else
        response = response.in(
          'id',
          imagesWithTags.data?.map((tag) => tag.image_id),
        );
    }

    const completed = await response;

    if (completed.error) {
      toast.error('Failed to get images: ' + completed.error.message);
      return [];
    }

    return completed.data ?? [];
  }, [supabase, filterTags]);
  const imagesRequest = useRequest(getImages, { refreshDeps: [filterTags] });

  return (
    <div className="my-5 grid gap-5">
      <div className="grid gap-3">
        <DefaultComponents.h3>Galleries</DefaultComponents.h3>
        <ImageGalleryList />
      </div>
      <hr className="my-2 border border-gray-800" />
      <div className="grid gap-3">
        <DefaultComponents.h3>Images</DefaultComponents.h3>
        <div className="grid gap-2">
          <div className="flex items-center rounded-lg border-2 border-gray-800 text-white">
            <div className="p-2">
              <FunnelIcon className="h-8 w-8 shrink-0 rounded-full bg-gray-800 p-2" />
            </div>
            <TagListInput value={filterTags} placeholder="Filter by tag..." onChange={setFilterTags} />
          </div>
          <UploadFiles
            onComplete={({ dbResult }) => {
              if (dbResult.error) {
                toast.error(`Failed to upload image: ${dbResult.error.message}`);
              } else {
                imagesRequest.refresh();
                toast.info(`${dbResult.data?.length ?? 0} Image${dbResult.data?.length > 1 ? 's' : ''} uploaded`);
              }
            }}
          />
        </div>
        <ImageList images={imagesRequest.data ?? []} />
      </div>
    </div>
  );
}

export default GalleryEditor;
