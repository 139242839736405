import usePermissions from '../hooks/UsePermission.tsx';
import { ErrorCard } from '../components/ErrorPanel.tsx';
import HeaderLinks from './HeaderLinks.tsx';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DefaultComponents } from '../components/DefaultComponents.tsx';
import { Result } from 'ahooks/lib/useRequest/src/types';
import { Tables } from '../data/Database.ts';
import { useRequest } from 'ahooks';
import { useSupabase } from '../data/SupabaseClientProvider.tsx';
import TagPill, { autoTagColor } from '../components/TagPill.tsx';
import { TrashIcon } from '@heroicons/react/24/outline';
import GalleryEditor from '../components/images/GalleryEditor.tsx';
import Spinner from '../components/Spinner.tsx';
import { PopoverButton } from '../components/PopoverButton.tsx';
import { SwatchIcon } from '@heroicons/react/24/solid';
import Colorful from '@uiw/react-color-colorful';
import Color from 'colorjs.io';
import { hexToHsva, hsvaToHex } from '@uiw/color-convert';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';

/** This view exposes several configurations and things to manage the application. Not visible to those without write permissions */
function DashboardHome() {
  const { h1: H1, h2: H2 } = DefaultComponents;

  const { canWrite } = usePermissions();
  const supabase = useSupabase().getContext();
  const alltagsRequest: Result<Tables<'Tags'>[], never> = useRequest(async () => {
    const tagReq = await supabase.from('Tags').select();
    return tagReq.data ?? [];
  });
  const sortedTags = useMemo(
    () => alltagsRequest.data?.sort((a, b) => a.name?.localeCompare(b.name ?? '') ?? 0) ?? [],
    [alltagsRequest.data],
  );

  if (!canWrite) {
    return (
      <div className={'flex h-full w-full flex-col overflow-y-auto'}>
        <HeaderLinks as="div" />
        <ErrorCard
          error={{
            message:
              'You do not have permission to view this page. Please contact the administrator if you believe this is an error.',
          }}
        />
      </div>
    );
  }

  return (
    <div className={'flex h-full w-full flex-col overflow-y-auto'}>
      <HeaderLinks as="div" />
      <div className="mb-2 p-5 text-center">
        <H1>Dashboard</H1>
        <H2>Looking to change something? It&apos;s probably here.</H2>
      </div>
      <TagTable allTags={sortedTags} onSave={() => alltagsRequest.refresh()} isLoading={alltagsRequest.loading} />
      <div className="grid gap-4 p-5 lg:px-24">
        <div className="rounded-md border-2 border-gray-800 p-4">
          <GalleryEditor />
        </div>
      </div>
    </div>
  );
}

function TagTable({
  allTags,
  isLoading,
  onSave,
}: {
  allTags: Tables<'Tags'>[];
  isLoading?: boolean;
  onSave?: () => void;
}) {
  const { h4: H4 } = DefaultComponents;
  const supabase = useSupabase().getContext();

  const handleTagChange = async (updatedTag: Tables<'Tags'>) => {
    const result = await supabase.from('Tags').upsert([updatedTag]);
    if (result.error) {
      toast.error(`Failed to update tag ${updatedTag.name}: ${result.error.message}`);
    } else onSave?.();
  };

  const handleTagDelete = async (tag: Tables<'Tags'>) => {
    const result = await supabase.from('Tags').delete().eq('id', tag.id);
    if (result.error) {
      toast.error(`Failed to delete tag ${tag.name}: ${result.error.message}`);
    } else {
      toast.success(`Deleted tag ${tag.name}`);
      onSave?.();
    }
  };

  return (
    <div className="grid gap-4 p-5 lg:px-24">
      <div className="rounded-md border-2 border-gray-800 p-4">
        <div className="flex items-center gap-5">
          <H4>Tags</H4>
          {isLoading && <Spinner className="p-1" text="Loading tags..." />}
        </div>
        {allTags.length !== 0 && (
          <DefaultComponents.table>
            <DefaultComponents.thead>
              <tr>
                <DefaultComponents.th>Name</DefaultComponents.th>
                <DefaultComponents.th>Color</DefaultComponents.th>
                <DefaultComponents.th>Actions</DefaultComponents.th>
              </tr>
            </DefaultComponents.thead>
            <DefaultComponents.tbody>
              {allTags.map((tag) => (
                <TagEditor tag={tag} key={tag.id} onChange={handleTagChange} onDelete={() => handleTagDelete(tag)} />
              ))}
            </DefaultComponents.tbody>
          </DefaultComponents.table>
        )}
      </div>
    </div>
  );
}

function TagEditor({
  tag,
  onChange,
  onDelete,
}: {
  tag: Tables<'Tags'>;
  onChange: (updatedTag: Tables<'Tags'>) => void;
  onDelete?: () => void;
}) {
  const navigate = useNavigate();
  const [color, setColor] = useState(new Color(tag.color ?? autoTagColor(tag)));
  const colorStr = color.to('srgb').toString({ format: 'hex' });
  const newTag = {
    ...tag,
    color: colorStr,
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnChange = useCallback(_.debounce(onChange, 1000, { trailing: true }), [onChange]);

  // Call debounced on change when color string changes
  useEffect(() => {
    if (JSON.stringify(tag) !== JSON.stringify(newTag)) debouncedOnChange?.(newTag);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colorStr]);

  return (
    <tr key={tag.id}>
      <DefaultComponents.td>
        <TagPill
          item={tag}
          key={tag.id}
          className="w-full"
          onClick={() => {
            navigate(`/articles?tags=${tag.name}`);
          }}
        />
      </DefaultComponents.td>
      <DefaultComponents.td>
        <div className="flex items-center justify-between">
          <input
            className="border-transparent bg-transparent text-white"
            defaultValue={tag.color ?? color.toString({ format: 'hex' })}
            onBlur={(e) => setColor(new Color(e.target.value))}
          />
          <span className="empty:hidden">{!tag.color ? '(NULL)' : null}</span>
          <PopoverButton
            className="rounded-md p-2 text-white hover:bg-gray-700"
            button={<SwatchIcon className="aspect-square h-5" />}
          >
            <div className="bg-gray-900 p-3 shadow-lg">
              <Colorful
                color={hexToHsva(color.to('srgb').toString({ format: 'hex' }))}
                onChange={(color) => {
                  setColor(new Color(hsvaToHex(color.hsva)));
                }}
              />
            </div>
          </PopoverButton>
        </div>
      </DefaultComponents.td>
      <DefaultComponents.td>
        <button
          className="flex w-full items-center justify-center gap-2 rounded-md border-2 border-red-500 bg-red-800 bg-opacity-0 p-2 text-red-500 transition-opacity hover:bg-opacity-50"
          type="submit"
          onClick={() => {
            if (
              confirm(
                'Are you sure you want to delete this tag? This cannot be undone and will untag everything that references it.',
              )
            )
              onDelete?.();
          }}
        >
          <TrashIcon className="h-4 w-4" />
          <span>Delete</span>
        </button>
      </DefaultComponents.td>
    </tr>
  );
}

export default DashboardHome;
