import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { useSupabase } from '../data/SupabaseClientProvider';
import _ from 'lodash';
import { useSupabaseAuthContext } from '../data/SupabaseAuthentication';

export interface UserPermissions {
  canWrite: boolean;
}

const PermissionsContext = createContext<UserPermissions>({
  canWrite: false,
});

export default function usePermissions(): UserPermissions {
  return useContext(PermissionsContext);
}

export function PermissionsContextProvider({ children }: { children: ReactNode }) {
  const { session } = useSupabaseAuthContext();
  const supabase = useSupabase();
  const [userPermissions, setUserPermissions] = useState<UserPermissions>({ canWrite: false });

  const fetchPermissions = useCallback(async () => {
    if (session !== null) {
      const response = await supabase.getContext().from('UserRoles').select('role').eq('user_id', session?.user.id);

      const { data, error } = response;

      if (error) {
        console.error(error);
        setUserPermissions({ canWrite: false });
        return;
      }

      if (data && data[0]) {
        const role = data[0].role;
        const canWrite = role === 'admin' || role === 'editor';
        console.log(`User has write permissions: ${canWrite}`);
        setUserPermissions({ canWrite });
      }

      return response.data;
    } else {
      console.log('No session. User has no write permissions.');
      setUserPermissions({ canWrite: false });
    }
  }, [supabase, session]);

  const debouncedFetchPermissions = useCallback(_.debounce(fetchPermissions, 1000, { trailing: true }), [
    fetchPermissions,
  ]);

  useEffect(() => {
    debouncedFetchPermissions();
  }, []);

  useEffect(() => {
    debouncedFetchPermissions();
  }, [session]);

  return <PermissionsContext.Provider value={userPermissions}>{children}</PermissionsContext.Provider>;
}
