import { classNames } from '../styling/StylingUtils';
import { CSSProperties, useEffect, useState } from 'react';

export interface SpinnerProps {
  className?: string;
  children?: React.ReactNode;
  text?: string;
  delay?: number;
  style?: CSSProperties;
}

function ComposedSpinner({ className, children, text, delay = 0, style }: SpinnerProps): JSX.Element {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, delay);

    return () => clearTimeout(timer);
  }, [delay]);

  const visibilityClass = isVisible ? 'opacity-100' : 'opacity-0';

  if (text !== undefined) {
    return (
      <div
        style={style}
        className={classNames(`flex gap-2 text-gray-100 transition-opacity`, visibilityClass, className ?? 'p-10')}
      >
        <ComposedSpinner className="h-5 w-5 shrink-0">{text}</ComposedSpinner>
      </div>
    );
  }

  return (
    <>
      <Spinner svgClass={className} spinnerClass="text-gray-100 opacity-75" />
      {children}
    </>
  );
}

function Spinner({ svgClass, spinnerClass }: { svgClass?: string; spinnerClass?: string }) {
  return (
    <svg className={`animate-spin ${svgClass}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
      <path
        className={spinnerClass}
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
}

export default ComposedSpinner;
