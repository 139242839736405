import {ReactNode} from "react";
import {Link} from "react-router-dom";

export default function SquareCard(props: {
    title: string;
    description: string;
    icon: ReactNode;
    href: string;
}) {
    // Displays a card with a title and a hidden description that
    // appears on hover. The icon image is displayed as the background

    return (
        <Link to={props.href}>
            <div
                className="relative font-roboto group min-w-28 sm:min-w-32 md:min-w-40 lg:min-w-72 aspect-square border-2 bg-gray-900/50 border-gray-800 overflow-hidden rounded-xl">
                <div className="w-full h-full p-10 md:p-12 lg:p-24">
                    {props.icon}
                </div>
                <div className="transition-all flex flex-col gap-1 sm:gap-2 md:gap-5 p-2 lg:p-10 absolute h-full w-full top-0 left-0 group-hover:bg-gray-800/85">
                    <h2 className="text-center font-bold text-md lg:text-2xl">{props.title}</h2>
                    <p className="text-xs md:text-sm lg:text-md text-transparent text-center grow transition-all group-hover:text-white">{props.description}</p>
                </div>
            </div>
        </Link>
    )
}