import useAnimatedText from '../../hooks/UseAnimatedText.ts';
import React, { useState } from 'react';
import { DefaultComponents } from '../../components/DefaultComponents.tsx';
import { DynamicImage, ImageGalleryPreview } from '../../components/images/ImageGallery.tsx';
import { classNames } from '../../styling/StylingUtils.ts';
import { isBetween } from '../../utils/NumberUtils.ts';
import { Battery50Icon, UserGroupIcon, UserIcon, WalletIcon } from '@heroicons/react/24/solid';
import { BookOpenIcon, BuildingOffice2Icon, TrophyIcon } from '@heroicons/react/24/outline';
import _ from 'lodash';
import Modal, { ModalSizes } from '../../components/Modal.tsx';
import { SignedCodeSnippet } from '../../components/articles/ViewCode.tsx';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const listInputSourceCodeText = require('!!raw-loader!../../components/images/ImageGallery.tsx').default;

function ImageGalleryDemo() {
  const { p: P, h1: H1, h2: H2, a: A } = DefaultComponents;
  const [showCode, setShowCode] = useState(false);
  const title = useAnimatedText({
    text: 'ImageGallery.tsx',
    delay: 150,
  });

  return (
    <div className="grid w-full px-10 py-3 text-white">
      <h1 className="w-fit rounded-full bg-gray-950/50 p-1 px-3 text-lg text-gray-200">
        <code>{title}</code>
      </h1>
      <P>A collection of components for previewing, editing, and navigating a set of images.</P>
      <div className="grid grid-cols-2 gap-5 px-5 py-10 lg:p-10">
        <ImageGalleryPreview
          name={'Super cool example gallery with an extra long name with a ton of characters! This is long...'}
          images={fakeImages}
          galleryTags={fakeTags}
        />
        <ImageGalleryPreview name={'Super cool example gallery'} images={fakeImagesTwo} galleryTags={fakeTags} />
      </div>
      <P>
        The gallery is designed to show small previews of images, which I dynamically blur using an external library,
        enticing the user to click the play button.
      </P>
      <P>
        By default, opening the showcase immediately autoplays the image gallery, iterating over the list of images with
        a preset timer. The user can choose to enable cycles and have the images play on an infinite loop, as well as
        navigating using the arrow buttons or keys.
      </P>
      <P>
        I intend on expanding this component to allow for a true fullscreen display of images, but this is work for a
        future iteration. For now, this component does the job, and I will continue to focus on other aspects of the
        website.
      </P>
      <p>Well... that&apos;s it. Stay tuned for more revolutionary code snippets and demos from the forloopcowboy.</p>
      <div className="flex justify-center py-5">
        <button
          className="bg-none px-2 py-1 text-blue-600 hover:underline focus:underline"
          onClick={() => setShowCode(true)}
        >
          View source
        </button>
      </div>
      <Modal maxWidth={ModalSizes.XXL} isOpen={showCode} closeModal={() => setShowCode(false)}>
        <SignedCodeSnippet
          title="ImageGallery.tsx"
          subtitle={
            "Feel free to copy paste! This is the full source code for the ImageGallery component and it's helpers."
          }
          code={'```tsx \n' + listInputSourceCodeText + '\n```'}
        />
      </Modal>
    </div>
  );
}

const fakeImages: DynamicImage[] = Array.from({ length: 7 }, (v, index) => {
  const colorClass = getColorClass();

  return function FakeDynamicImage() {
    return (
      <div
        key={index}
        className={classNames(colorClass, 'flex aspect-video grow items-center justify-center p-2 md:p-3 lg:p-6')}
      >
        {(() => {
          switch (index) {
            case 0:
              return <UserIcon className="aspect-square h-8" />;
            case 1:
              return <BookOpenIcon className="aspect-square h-8" />;
            case 2:
              return <WalletIcon className="aspect-square h-8" />;
            case 3:
              return <BuildingOffice2Icon className="aspect-square h-8" />;
            case 4:
              return <Battery50Icon className="aspect-square h-8" />;
            case 5:
              return <UserGroupIcon className="aspect-square h-8" />;
            case 6:
              return <TrophyIcon className="aspect-square h-8" />;
          }
        })()}
      </div>
    );
  };
});

const fakeImagesTwo = _.shuffle(fakeImages);

const fakeTags = [
  { id: 1, name: 'Cool Icons' },
  { id: 3, name: "Trip to Mars '27" },
  { id: 5, name: 'Europe' },
];

// Returns a random color
function getColorClass() {
  const rand = Math.random();

  if (isBetween(rand, [0, 0.1])) return 'bg-red-500 text-white';
  if (isBetween(rand, [0.1, 0.2])) return 'bg-secondary-blue text-white';
  if (isBetween(rand, [0.2, 0.3])) return 'bg-green-500 text-white';
  if (isBetween(rand, [0.3, 0.4])) return 'bg-yellow-500 text-yellow-900';
  if (isBetween(rand, [0.4, 0.5])) return 'bg-orange-500 text-orange-900';
  if (isBetween(rand, [0.5, 0.6])) return 'bg-red-100 text-red-900';
  if (isBetween(rand, [0.6, 0.7])) return 'bg-blue-700 text-blue-100';
  if (isBetween(rand, [0.7, 0.8])) return 'bg-yellow-200 text-yellow-900';
  else return 'bg-red-800 text-white';
}

export default ImageGalleryDemo;
